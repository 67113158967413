/* ============== search form ============== */

.search-container {
  display: flex;
  align-items: center;
}

.form-search {
  background-color: #404855;
  color: #fff;
  display: flex;
  align-items: center;
  height: 44px;
  border-radius: var(--radius);
  position: relative;
  padding-right: 10px;
  margin-right: 13px;
}

.theme-light .form-search {
  background-color: #fff;
  border: 1px solid var(--font-color);
  color: var(--font-color);
}

.form-search input[type=text] {
  background: transparent;
  color: white;
  border: none;
}

.theme-light .form-search input[type=text] {
  color: var(--font-color);
}

.form-search .btn.btn-danger {
  border: none;
}

#search-topbar-form.form-search .select2-container,
#search-topbar-form.form-search .ts-control {
  width: 200px !important;
  background: rgba(255, 255, 255, 0.05);
  margin-right: 12px;
  border: none;
  border-radius: var(--radius) 0 0 var(--radius);
}

#search-topbar-form.form-search .select2 .select2-choice,
#search-topbar-form.form-search .ts-control {
  background: transparent;
  color: white;
  text-align: right;
  height: 44px;
  line-height: 44px;
  display: flex;
  align-items: center;
  padding: 0;
}

#search-topbar-form.form-search .ts-control {
  background: rgba(255, 255, 255, 0.05);
  cursor: pointer;
  padding-right: 0 !important;
  width: 170px !important;
}

.theme-light #search-topbar-form.form-search .ts-control,
.theme-light #search-topbar-form.form-search .select2-container {
  color: var(--font-color);
  background-color: var(--blue-lighter);
  border: none;
  height: 42px;
  line-height: 42px;
}

#search-topbar-form.form-search .ts-wrapper {
  margin-right: 12px;
}

#search-topbar-form.form-search .ts-wrapper.single .ts-control::after {
  display: none;
}

.form-search .btn,
.form-search .select2 .select2-choice,
.form-search .ts-control {
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 500;
}

.form-search svg.fa-chevron-down,
.form-search i.fa-chevron-down {
  color: var(--primary-color);
  position: absolute;
  left: 10px;
  pointer-events: none;
  z-index: 100;
}

.form-search .select2 .select2-arrow {
  display: none;
}

.form-search .select2-container .select2-choice > .select2-chosen,
#search-topbar-form.form-search .ts-control {
  margin-right: 0;
  padding-right: 10px;
  padding-left: 30px;
  box-sizing: content-box;
}

.form-search select.select2,
#search-topbar-form.form-search select.tom-select {
  visibility: hidden;
}

.form-search .ts-wrapper .clear-button {
  display: none;
}

#select2-drop.type-selector-options {
  border-radius: var(--radius);
  border: none;
  box-shadow: none;
  margin-top: 1px;
}

.type-selector-options::after,
.user-dropdown::after,
#topbar-quick-add .dropdown-menu::after,
.search-topbar-form.ts-dropdown::after {
  content: '';
  position: absolute;
  left: 50px;
  top: -4px;
  width: 0;
  height: 0;
  border: 4px solid transparent;
  border-bottom-color: #fff;
  margin-top: -4px;
}

.user-dropdown::after {
  left: auto;
  right: 20px;
}

.type-selector-options .select2-search {
  display: none;
}

.type-selector-options .select2-results,
.search-topbar-form.ts-dropdown {
  width: 458px !important;
  position: relative;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  border: none;
  padding: 6px;
  max-height: none;
  z-index: 1011;
}

.search-topbar-form--portal.ts-dropdown {
  width: 211px !important;
}

.search-topbar-form.ts-dropdown .ts-dropdown-content {
  display: flex;
  flex-wrap: wrap;
}

.search-topbar-form.ts-dropdown {
  position: absolute;
}

.search-topbar-form.ts-dropdown .dropdown-input-wrap {
  display: none;
}

.type-selector-options .select2-result,
.search-topbar-form.ts-dropdown .option {
  width: 211px;
  border-bottom: none;
  font-size: 12px;
  padding: 0;
  margin: 6px;
  position: relative;
}

.search-topbar-form.ts-dropdown .ts-dropdown-content {
  max-height: none;
}

.type-selector-options .select2-result .select2-result-label,
.search-topbar-form.ts-dropdown .option {
  padding: 10px 15px;
  border-radius: var(--radius);
  color: var(--font-color);
}

.type-selector-options .select2-result:last-of-type,
.search-topbar-form.ts-dropdown .option:last-of-type {
  width: calc(458px - 10px);
  border-bottom: none;
  font-size: 12px;
  padding: 0;
}

.search-topbar-form.ts-dropdown .option:last-of-type {
  padding: 10px 15px;
}

.type-selector-options .select2-result .select2-result-label:hover,
.type-selector-options .select2-result.select2-highlighted .select2-result-label {
  background: var(--blue-lighter);
}

.type-selector-options .select2-result.select2-highlighted {
  background: transparent;
}

.type-selector-options .select2-result-label::before,
.search-topbar-form.ts-dropdown .option::before,
.search-topbar-form.ts-dropdown .option.active::after {
  content: "\f2c2";
  font-size: 12px;
  margin-right: 3px;
  margin-left: -3px;
  width: 18px;
  display: inline-block;
  color: var(--primary-color);
  font-family: 'Font Awesome\ 5 Free', sans-serif;
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.search-topbar-form--portal.ts-dropdown .option::before {
  color: var(--font-color);
}

.search-topbar-form.ts-dropdown .option.active {
  font-weight: var(--font-weight-bold);
}

.search-topbar-form.ts-dropdown .option.active::after {
  content: "\f00c";
  position: absolute;
  top: 12px;
  right: 10px;
  font-size: 10px;
  width: 10px;
}

.search-topbar-form.ts-dropdown .option.active::before {
  color: var(--font-color);
}

.type-selector-options .tso-applications .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="applications"]::before {
  content: "\f2c2";
  font-weight: 300;
}

.type-selector-options .tso-policies .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="policies"]::before {
  content: "\f2c2";
}

.type-selector-options .tso-users .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="users"]::before {
  content: "\f007";
}

.type-selector-options .tso-payments .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="payments"]::before {
  content: "\f53a";
}

.type-selector-options .tso-agencies .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="agencies"]::before {
  content: "\f2b5";
}

.type-selector-options .tso-receipts .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="receipts"]::before {
  content: "\f543";
}

.type-selector-options .tso-companies .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="marketingcompanies"]::before {
  content: "\f0a1";
}

.type-selector-options .tso-suppliers .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="suppliers"]::before {
  content: "\f466";
}

.type-selector-options .tso-carriers .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="carriers"]::before {
  content: "\f4ce";
}

.type-selector-options .tso-tax .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="taxauthorities"]::before {
  content: "\f557";
}

.type-selector-options .tso-all .select2-result-label::before,
.search-topbar-form.ts-dropdown .option[data-value="all"]::before {
  content: "\f0c8";
  font-weight: 300;
}

.search-topbar-form.ts-dropdown .option.active[data-value="all"]::before {
  content: "\f14a";
  font-weight: 900;
}

.search-topbar-form.ts-dropdown .option.active[data-value="all"]::after {
  display: none;
}
