.pager-container {
  overflow: auto;
  background-color: var(--main-bg);
}

.pager {
  float: right;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.pager button {
  color: var(--primary-color);
  border: 2px solid var(--smooth-gray);
  background: transparent;
  width: 38px;
  height: 38px;
  border-radius: 3px;
}

.pager button:focus {
  outline: none;
}

.pager button.disabled {
  color: var(--disabled-dark-color);
}

.pager button.disabled:hover {
  cursor: default;
}

.pager button:hover {
  background-color: var(--main-bg);
  cursor: pointer;
}

.pager .table-pagination-current {
  padding: 10px 15px;
  color: var(--lightmid-gray);
}

.pager-container {
  overflow: auto;
}

.pager-container > .pager {
  padding: 5px 10px;
}
