#confirm-dialog {
  border-radius: 8px;
  border: 1px solid #858d9d;
  padding: 0;
  font-size: var(--font-size-heading);
  color: var(--font-color);
}

#confirm-dialog::backdrop {
  background-color: rgba(70, 67, 67, 0.5);
}

.confirm-dialog__inner {
  width: auto;
  min-width: 450px;
  max-width: 700px;
}

#confirm-dialog__title {
  margin-bottom: 0;
  font-weight: 500;
  font-size: var(--large-font-size);
}

#confirm-dialog__title:not(:empty) {
  border-bottom: 1px solid var(--light-gray);
  padding: 1rem;
}

#confirm-dialog__body,
.confirm-dialog__footer {
  padding: 1rem;
}

#confirm-dialog__body li {
  margin-bottom: 0.5rem;
  color: var(--font-color);
}

#confirm-dialog__body h3 {
  font-size: 14px;
}

#confirm-dialog__body li:last-of-type {
  margin-bottom: 0;
}

.confirm-dialog__footer {
  text-align: right;
  border-top: 1px solid var(--light-gray);
  background: var(--light-gray-bg);
}
