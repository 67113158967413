:root {
  --primary-color: #3ab1eb;
  --primary-color-004: rgba(58, 177, 235, 0.04);
  --primary-dark: #178bde;
  --secondary-color: #fff;
  --success-color: #68ca2c;
  --success-dark: #52ab1b;
  --success-light: #c3eaab;
  --info-color: #ccc;
  --warning-color: #ccc;
  --warning-dark: #e69208;
  --danger-color: #d97361;
  --danger-light: #f8d7da;
  --danger-dark: #eb5767;
  --purple-color: #5d42bf;
  --teal-color: #3b6d83;
  --orange-color: #f45d3c;
  --header-color: #2a3342;
  --blue-dark: #223965;
  --blue-dark-rgb: 34, 57, 101;
  --blue-darker: #1e335a;
  --blue-darker-rgb: 30, 51, 90;
  --blue-color: #347fad;
  --blue-light: #dbf4fa;
  --blue-lighter: #f0f8fc;
  --red-color: #d97361;
  --green-dark: #457e61;
  --green-light: #a3db97;
  --disabled-color: #f4f5f7;
  --disabled-dark-color: #a6aeb7;
  --disabled-text-color: rgb(127, 127, 127);
  --thin-spacing: 6px;
  --mid-spacing: 12px;
  --lg-spacing: 20px;
  --gutter-w: 15px;
  --font-light: #768390;
  --font-color: #4b5167;
  --font-help-text: #aaa;
  --font-dark: #000;
  --light-gray: #d7dee5;
  --light-gray-bg: #f9fafb;
  --lightmid-gray: #c1c5ca;
  --lightblue-gray: #f7fbfd;
  --mid-gray: #858d9d;
  --smooth-gray: #e5ecf0;
  --dark-gray: #545f6b;
  --side-nav-gray: #222830;
  --input-bg: #fff;
  --white-bg: #fff;
  --darker-bg: #1b212a;
  --smooth-white-bg: #fafbfc;
  --radius: 3px;
  --lg-radius: 8px;
  --smooth-shadow: 2px -2px 5px 1px rgba(0, 0, 0, 0.1);
  --smooth-shadow-bottom: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
  --shadow: 0 8px 16px rgba(44, 51, 65, 0.1), 0 2px 4px rgba(44, 51, 65, 0.3);
  --link-color: var(--primary-color);
  --link-dark: #179ddf;
  --h1-size: 30px;
  --h2-size: 24px;
  --h3-size: 16px;
  --font-size: 12px;
  --small-font-size: 10px;
  --large-font-size: 18px;
  --font-size-heading: 14px;
  --transition: all 0.2s 0.01s cubic-bezier(0.075, 0.82, 0.165, 1);
  --font-weight: 500;
  --font-weight-thin: 300;
  --font-weight-bold: 700;
  --font-family: gotham, sans-serif;
  --icon-font: "Font Awesome 5 Free", sans-serif;
  --top-bar-height: 70px;
  --focus-outline: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  --main-bg: var(--white-bg);
  --sidebar-bg: var(--light-gray-bg);
  --body-bg: #f1f5f9;
}
