html,
body {
  height: calc(100% - 90px) !important;
}

body.template-editor {
  height: calc(100% + 90px) !important;
}

body.file-viewer-container {
  height: 100% !important;
}

.navbar-inner .navbar-brand {
  line-height: 1.5rem;
  margin: 0 1rem 0 0;
}

.toolbar,
.sub-row,
.template-editor .info,
.template-editor .twig,
.template-editor .css,
.circle-icon { /* TODO: Remove it after adding bootstrap reset */
  box-sizing: content-box;
}

.oauth-token-issue-banner {
  top: 70px;
  position: absolute;
}

.dropdown-toggle::after {
  display: none !important;
}

.modal-body {
  padding: 0;
}

.accordion,
.accordion-group {
  height: 100%;
}

.box.accordion-group {
  margin-bottom: 0;
}

.conversations-box .conversation-new-message .new-message-input textarea {
  box-sizing: border-box;
}

.conversations-box .conversation-new-message .new-message-buttons {
  padding-right: 15px;
  padding-left: 15px;
}

#conversations-content .conversation-tools {
  min-height: 280px;
}

ul {
  padding-inline-start: 0;
  margin-block-start: 0;
  margin-block-end: 0;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle,
.btn-primary:focus {
  background-color: #3ab1eb !important;
  border-color: #3ab1eb !important;
  box-shadow: none !important;
}

.navbar .container,
.navbar .container-fluid {
  flex-wrap: nowrap;
}

.table.pagination,
.table-listing.pagination {
  display: table !important;
}

.nav-tabs {
  border-bottom: none;
}

.navbar,
.select2-container .select2-choice {
  padding: 0;
}

.select2-container .select2-choice,
.select2-container .select2-choices {
  box-sizing: content-box;
  align-self: center;
  justify-content: space-between;
  width: 100%;
}

.select2-container .select2-choice {
  display: flex;
}

.select2-container .select2-choice .select2-arrow {
  top: -2px;
}

*:not(.form-search) > .select2-container {
  display: flex;
  width: 100%;
  max-width: 100%;
}

.tax-playground .add {
  width: 100%;
}

.navbar-brand:hover {
  color: #fff;
}

.collection-item > .row > .control-group {
  width: 100%;
}

.collection-item > .row > .control-group > .controls {
  display: flex;
  flex-wrap: wrap;
}

.row-file-tag-headers [class^=col-] {
  margin-bottom: 12px;
}

.transaction-table-content {
  box-sizing: border-box;
}

table .span12 {
  width: 100%;
  display: block;
}

.table-listing-container {
  float: none !important;
}

.btn-soft:hover {
  color: #2d99cf;
}

.btn-border-grey:hover,
.btn-attach:hover {
  color: #999 !important;
}

.btn-grey:hover {
  color: #000 !important;
}

label {
  margin-bottom: 0;
}

.controls {
  box-sizing: content-box;
}

.container-fluid.no-padding {
  padding-right: 0;
  padding-left: 0;
}

.radio {
  margin-right: 0.5rem;
}

.main-column .content-page-header {
  align-items: center;
  margin: 0 0 15px 0;
}

.company-directory .users {
  margin: 0 !important;
  padding: 0 20px;
}

.status {
  box-sizing: content-box;
}

.row-stats {
  margin-bottom: 0.5rem;
}

.widget-performance .col-6:first-child .box {
  margin-right: 0.5rem;
}

.report-business-plan .col-7 {
  padding-right: 0.5rem;
}

.nav .nav-tabs {
  box-sizing: content-box;
}

.btn-group.show .dropdown-menu {
  display: block;
}

.nav {
  display: block;
}

label {
  margin-bottom: 0;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a.btn-standard:hover,
a.btn-primary:hover,
a.btn:hover:not(.btn-outline-*) {
  color: #fff;
}

b,
strong {
  font-weight: bold !important;
}

label.control-label {
  margin-right: 0.5rem;
}

.actions .action {
  box-sizing: content-box;
}

a.btn-soft:hover {
  color: #3ab1eb;
}

.container {
  max-width: 100% !important;
}

.select2-container,
.checkbox-tick + label {
  box-sizing: content-box !important;
}

div.row {
  margin-left: 0;
  margin-right: 0;
}

@media (min-width: 1200px) {
  .dashboard .admin-section {
    padding: 0 !important;
  }
}

.app-form .btn-new-insured-wrap > div {
  margin-left: -20px;
}

.accordion-toggle {
  margin: 0 auto;
}

.nav-tabs-buttons-dynamic {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.tab-pane .nav-tabs-buttons-dynamic {
  padding: 10px;
}

.form-horizontal .select2-container {
  max-width: 80%;
  min-width: 80%;
}

.settings-value {
  box-sizing: content-box;
}

.agencyRanking,
.table-listing-container-wide {
  width: 100%;
}

.content-page-header {
  display: flex !important;
  align-items: baseline;
  width: 100%;
}

.mailpane .panel-pane {
  box-sizing: content-box;
}

.input-append .btn {
  padding-bottom: 10px !important;
}

.live-search .search-all-results,
.live-search .search-results-table {
  line-height: 100% !important;
}

.modal-content {
  box-sizing: content-box;
}

.input-append .progress,
.input-append input[type=text] {
  box-sizing: border-box;
  display: inline-block;
}

.content-page-header > h2 {
  flex: 1;
}

.content-page-header > .btn {
  justify-self: flex-end;
  float: none;
}

@media (min-width: 1200px) {
  .dashboard .admin-section {
    padding: 0;
  }
}

.flex-end {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.sidebar-tabs ul li {
  padding: 0 !important;
}

.sidebar-tabs ul li a {
  background-color: #fff;
  padding: 15px 30px;
  position: relative;
  border-bottom: 1px solid #eaeaea;
}

.modal {
  top: 0 !important;
}

.modal-large {
  margin-left: calc(50% + -470px) !important;
}

.actions .action a {
  box-sizing: content-box;
}

.radio input[type="radio"] {
  vertical-align: text-top;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
  }

  .modal-dialog.modal-large {
    max-width: 90%;
  }
}

.sidebar-tabs ul li a.active {
  background-color: #fafbfc;
  color: #3ab1eb;
  border-right: 1px solid #fafbfc;
  border-left: 4px solid #3ab1eb;
  margin-right: -1px;
}

.form-horizontal .control-label {
  float: left;
  width: 140px;
  padding-top: 5px;
}

.form-inline label {
  justify-content: left;
}

.row,
div:not(.standard) > .control-group {
  width: 100%;
}

.icon-white {
  color: white;
}

.transaction-table-content .sub-row-data .btn-primary {
  margin-top: 12px;
}

.social-card .avatar-wrap .avatar-60 {
  min-width: 60px;
}

.nav-tabs-dynamic a.active:not(.nav-link) {
  opacity: 1;
  border-bottom: 3px solid #20b1ef;
}

.form-horizontal .controls {
  margin-left: 160px;
}

.table-listing-container {
  padding-bottom: 10px;
}

.collection-inline > .row > div {
  width: 100%;
}

.form-production-report .percentage-input {
  box-sizing: content-box;
  width: 44px !important;
}

input[type=checkbox] {
  margin-right: 6px;
}

.dropdown-menu {
  padding: 0 !important;
}

.sub-row .fa-pulse {
  box-sizing: content-box !important;
}

.files-table .label {
  color: #fff;
}

.label {
  font-size: 0.7em;
  font-weight: 700;
  line-height: 14px;
  color: #fff;
  vertical-align: baseline;
  white-space: nowrap;
  background-color: #999;
  padding: 1px 4px 2px;
  border-radius: 3px;
}

.label-success {
  background-color: #669533;
}

.label-failed,
.label-warning {
  background-color: #dd5600;
}

.row-submit .btn-right {
  margin: 0 !important;
}

.modal-panel .modal-body,
.modal-content .modal-body {
  overflow-y: auto !important;
}

.modal-footer {
  background-color: #f5f5f5;
}

.modal-body.social {
  padding: 1rem;
}

.modal-footer .footer {
  width: 100%;
}

.checklist span .dropdown-menu {
  left: inherit !important;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle,
.btn-info:focus,
.btn-info.focus {
  background-color: #435068;
  border-color: #435068;
}

.form-horizontal .help-inline,
.form-horizontal .input-append,
.form-horizontal .input-prepend,
.form-horizontal .uneditable-input,
.form-horizontal input,
.form-horizontal select,
.form-horizontal textarea,
.form-inline .help-inline,
.form-inline .input-append,
.form-inline .input-prepend,
.form-inline .uneditable-input,
.form-inline input,
.form-inline select,
.form-inline textarea,
.form-search .help-inline,
.form-search .input-append,
.form-search .input-prepend,
.form-search .uneditable-input,
.form-search input,
.form-search select,
.form-search textarea {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
  box-sizing: content-box;
}

.form-horizontal .control-group::after,
.form-horizontal .control-group::before {
  display: table;
  content: "";
  line-height: 0;
}

.row::after,
.form-horizontal .control-group::after {
  clear: both;
}

/* check if can remove this once the old bootstrap is removed */

.row-headerless > [class*=col-],
.tab-pane-headerless .row:first-child [class*=col-] {
  padding-top: 20px;
}

.row [class*='col-']:not([class*='offset-md-']) {
  margin-left: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

#user-account-form .row-headerless > [class*=col-] {
  padding-bottom: 15px;
}

.row:not(.row-submit):not(.row-with-padding):not(.row-headerless):not(.row-file-tag-headers):not(.row-stats) > [class*='col-'],
.table-listing-wrapper .row:not(.row-with-padding):not(.row-headerless) [class*='col-'],
.row.settings-area > [class*='col-'],
.accounting-transaction .row:not(.row-submit):not(.row-with-padding) [class*='col-'],
.key-indicators .row:not(.row-with-padding) [class*='col-'] {
  padding-left: 0;
  padding-right: 0;
}

.row.columns-form > [class*='col-'] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.row-stats {
  width: 100%;
}

div.link .progress {
  height: auto;
  line-height: 100%;
}

div.link .progress.btn {
  line-height: 15px;
}

.region-export-actions {
  width: 200px;
}

.nav a.nav-link.active {
  color: #3ab1eb;
  background: #f9fafb;
}

.file-viewer-container .navbar {
  height: 70px !important;
}

.template-editor h2 {
  height: auto !important;
}

.actions ul.dropdown-menu.show,
.table-listing .actions ul.dropdown-menu.show {
  height: auto !important;
}

.file-viewer-container .navbar-inner {
  display: flex;
  width: 100%;
}

.file-viewer-container {
  margin-top: 70px !important;
}

.role-features-list-item .checkbox-tick + label + .label-text {
  top: 6px !important;
}

.collapse-button {
  font-size: 14px !important;
  line-height: 14px;
  color: #000 !important;
}

.collapse.show:not([data-show-hide-target]) {
  height: auto !important;
}

.accordion-body.collapse.show {
  overflow: unset;
}

.table-custom-report tr.collapse.show td {
  display: table-cell;
}

.collection-inline div.control-group {
  padding-left: 0;
}

.collection-inline div.control-group .collection-item {
  margin-bottom: 8px;
}

.row-with-padding:not(.full-padding) [class*='col-']:first-child {
  padding-left: 0;
}

.row-with-padding:not(.full-padding) [class*='col-']:last-child {
  padding-right: 0;
}

.social .likes a.liked .fa-heart {
  color: #f63733;
}

.likes .count {
  margin-left: 6px;
  box-sizing: content-box;
}

.accounting-bank-reconciliation .row-pre-table [class*=col-],
.accounting-deposit-details .row-pre-table [class*=col-],
.accounting-offset-details .row-pre-table [class*=col-],
.accounting-payment-details .row-pre-table [class*=col-],
.row-submit [class*=col-] {
  padding: 15px !important;
}

.box-content {
  display: block;
}

.form-production-report textarea {
  box-sizing: content-box;
}

.table thead th {
  border-bottom: none;
  border-top: none;
}

.table th,
.table td {
  padding: 8px;
}

.query-group .input-group input[type=text] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

/* fix layouts using it and remove */
.pull-right {
  float: right;
}

.flex-1 {
  flex: 1;
}
