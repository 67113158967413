
.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: var(--header-color);
  color: #fff;
}

.theme-light .navbar {
  background-color: #fff;
  color: var(--header-color);
  border-bottom: 1px solid var(--header-color);
}

.navbar-nav a {
  color: currentColor;
}

.navbar-nav {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
}

.navbar-nav a {
  display: block;
  padding-right: 2rem;
}

.navbar-nav a.active {
  text-decoration: underline;
}

.portal-navbar {
  z-index: 1000;
}

.portal-navbar .navbar-inner {
  width: 100%;
}

.navbar,
.navbar-inner,
.navbar .container {
  height: 70px;
}

.navbar form {
  float: left;
}

.navbar form input[type=text] {
  width: 200px;
  transition: width 0.3s ease;
}

.navbar ul {
  margin: 0;
  list-style-type: none;
}

.navbar .nav > li {
  float: left;
}

.navbar .navbar-user {
  float: none;
  position: fixed;
  right: 15px;
  top: 12px;
}

.navbar .navbar-user .btn-avatar {
  display: flex;
  align-items: center;
}

.navbar .navbar-user > li {
  margin-left: 10px;
}

.navbar .navbar-user .avatar-username {
  margin: 0 11px 0 13px;
  font-weight: 300;
  font-size: 14px;
  text-transform: none;
}

.navbar .navbar-task .dropdown-menu {
  min-width: 220px;
}

.navbar .user-dropdown {
  min-width: 190px;
}

.navbar .btn-avatar {
  display: inline-block;
  background: none;
  box-shadow: none;
  padding: 0;
  height: 44px;
}

.navbar .avatar {
  border-radius: 50%;
  height: 44px;
}

.navbar .avatar img,
.navbar .avatar-wrap {
  width: 44px;
  height: 44px;
}

.navbar .tasks {
  position: relative;
}

.navbar .tasks-incomplete {
  background-color: var(--danger-dark);
  color: #fff;
  border-radius: 100px;
  position: absolute;
  top: -8px;
  right: -5px;
  padding: 2px 5px 1px 5px;
  font-size: calc(var(--font-size) - 2px);
  min-width: 18px;
  text-align: center;
  display: none;
}

.navbar .tasks-incomplete.visible {
  display: block;
}

.navbar-brand {
  float: left;
  color: #fff;
  font-size: 1.5em;
  padding: 8px;
  margin: 17px 30px 8px 8px;
  text-decoration: none;
  text-transform: uppercase;
  border: 1px transparent solid;
  border-radius: 3px;
  letter-spacing: 1px;
}

.navbar-brand:hover {
  border-color: rgba(255, 255, 255, 0.7);
}

.navbar-brand .hq {
  font-weight: bold;
}

.navbar .call-log {
  display: none;
}

/* ========== navigation =========== */

.nav-main {
  position: fixed;
  top: var(--top-bar-height);
  height: calc(100vh - var(--top-bar-height));
  overflow-y: auto;
  list-style-type: none;
  background: var(--side-nav-gray);
  border-top: 1px solid var(--side-nav-gray);
}

.nav-main .nav-main-item {
  border-bottom: 1px solid #151b24;
}

.nav-main .nav-main-item-wrapper {
  text-align: center;
  color: #fff;
  padding: 10px 0 9px 0;
  transition: var(--transition);
}

.nav-main .active .nav-main-item-wrapper {
  color: var(--primary-color);
  opacity: 1;
  background: #151b24;
}

.nav-main .active .nav-main-item-wrapper * {
  opacity: 1;
  transform: scale(1);
}

.nav-main a {
  color: inherit;
  text-decoration: none;
}

.nav-main .nav-main-item:not(.active) a:hover .nav-main-item-wrapper {
  background: rgba(255, 255, 255, 0.05);
}

.nav-main a:hover .nav-main-item-wrapper * {
  opacity: 1;
}

.nav-main .nav-main-icon {
  width: 75px;
  font-size: 25px;
  line-height: 25px;
  height: 25px;
  margin-bottom: 4px;
  opacity: 0.3;
  transform: scale(0.9);
  transition: var(--transition);
}

.nav-main a:hover .nav-main-icon {
  transform: scale(1);
}

.nav-main .nav-main-label {
  font-size: 10px;
  letter-spacing: -0.1px;
  font-weight: var(--font-weight);
  opacity: 0.6;
  transition: var(--transition);
  word-wrap: break-word;
  max-width: 75px;
}

.nav-main .active {
  color: #36aae4;
  opacity: 1;
  background-color: #151b24;
  border-left: 1px solid var(--primary-color);
}

.nav-main .active .nav-main-label {
  opacity: 1;
  color: #36aae4;
}

.nav-main .active .nav-main-label > .label-band {
  display: block;
}

.nav-pills {
  position: fixed;
  background-color: #1b212a;
  top: 80px;
  left: 0;
  width: 100%;
  list-style-type: none;
  padding: 25px;
}

.nav-pills li {
  display: inline-block;
}

.nav-pills a {
  color: #fff;
  text-decoration: none;
  padding: 12px 15px;
  border-radius: 3px;
  font-family: Gotham Book, sans-serif;
  font-size: 0.8em;
  margin-right: 10px;
}

.nav-pills a:hover {
  background: #323e51;
}

.nav-pills .active a {
  background: #36aae4;
}

.nav-pills .active a:hover {
  background: #2995cb;
}

/* ========= sidebar tabs ============= */

.sidebar-tabs {
  border-right: 1px solid  var(--light-gray);
}

.sidebar-tabs ul {
  list-style-type: none;
}

.sidebar-tabs ul li {
  background-color: #fff;
  padding: 15px 30px;
  position: relative;
  border-bottom: 1px solid var(--light-gray);
}

.sidebar-tabs ul li.active {
  background-color: #fafbfc;
  color: #3ab1eb;
  border-right: 1px solid  var(--light-gray-bg);
  border-left: 4px solid var(--primary-color);
  margin-right: -1px;
}

.sidebar-tabs ul li:first-child {
  border-top: none;
}

.sidebar-tabs ul li a {
  color: var(--font-color);
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  border-bottom: none;
}

.sidebar-tabs ul li a:hover {
  background-color: var(--blue-lighter);
}

.sidebar-tabs ul li.active a {
  color: var(--primary-color);
}

/* ========= top actions section ============= */

.top-actions {
  background: #fafbfc;
  border-bottom: 1px solid #eee;
}

.top-actions .dropdown-menu {
  min-width: 175px;
}

/* ========= responsive ============= */

@media (max-width: 992px) {
  .navbar,
  .navbar-inner,
  .navbar .container {
    height: 60px;
    padding: 0 !important;
  }

  .navbar .navbar-user {
    top: 17px;
  }

  .navbar-brand {
    font-size: 20px;
    margin: 7px;
  }
}

.topbar-menu-icon a {
  display: flex;
  height: 44px;
  width: 44px;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  margin: 0 3px 0 0;
  border: 1px solid var(--white);
  border-radius: var(--radius);
  color: var(--white);
  font-size: 16px;
  line-height: 16px;
  box-sizing: border-box;
}

.topbar-menu-icon a:hover {
  color: var(--white);
}

.topbar-menu-icon a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.avatar-name-circle {
  font-weight: bold;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b212a;
  margin-right: 4px;
  letter-spacing: 0.5px;
}

.btn-avatar > div {
  transition: var(--transition);
}

.btn-avatar:hover > div:not(.avatar-wrap) {
  background-color: rgba(27, 33, 42, 0.7);
}

.btn-avatar:hover > div {
  transform: scale(1.1);
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .d-md-none {
    display: none !important;
  }
}

@media (max-width: 1198px) {
  .navbar .navbar-user > li.topbar-menu-icon {
    margin-left: 2px;
    transition: all 0.25s linear;
  }

  .live-search-visible .topbar-menu-icon,
  .live-search-visible .division-selector {
    display: none !important;
  }

  .navbar form input[type=text] {
    width: 120px;
    transition: width 0.3s ease-out;
  }

  .navbar form input[type=text]:focus {
    width: 200px;
  }
}

.navbar .topbar-menu-icon.mailpane-search,
.navbar .topbar-menu-icon.tasks {
  display: block;
}

#quickAddMenuButton {
  height: 44px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.navbar .navbar-user {
  top: 12px;
}
