
.nav-tabs {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: block;
  margin-bottom: -1px;
  position: relative;
  z-index: 110;
}

.nav-tabs,
#redactor_tabs {
  list-style-type: none;
}

.nav-tabs li,
#redactor_tabs a {
  display: inline-block;
  position: relative;
  white-space: nowrap;
  line-height: 40px;
  height: 40px;
}

.nav-tabs .tab-sublist.active > div > a {
  border: 1px solid;
  border-color: #dee2e6 #dee2e6 #fff;
  border-bottom: none;
}

.nav-tabs .tab-sublist .btn-group,
.nav-tabs .tab-sublist .btn-group .dropdown-toggle {
  display: block;
  height: 40px;
  text-decoration: none;
}

.nav-tabs .dropdown-menu li {
  height: auto;
}

.nav-tabs .dropdown-menu .nav-link {
  border: none;
}

.nav-tabs .dropdown-menu .nav-link:hover {
  background: var(--blue-lighter);
  border: none;
}

.nav-tabs .dropdown-menu .nav-link.active {
  background: var(--blue-lighter);
  color: var(--primary-color);
}

.nav-tabs a,
#redactor_tabs a {
  background-color: var(--light-gray);
  color: var(--font-light);
  font-size: 14px;
  font-weight: 500;
  line-height: 15px;
  text-decoration: none;
  padding: 14px 25px 12px;
  border-radius: 4px 4px 0 0;
  display: block;
  height: 40px;
}

.nav-tabs a:hover,
#redactor_tabs a:hover {
  color: rgba(42, 51, 66, 0.65);
}

.nav-tabs .active a,
#redactor_tabs a.redactor_tabs_act {
  color: #3ab1eb;
  background: #f9fafb;
}

.nav-tabs .highlight {
  background-color: var(--danger-dark);
  display: block;
  position: absolute;
  top: -4px;
  right: -3px;
  z-index: 1;
}

.nav-tabs .highlight-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  overflow: hidden;
  text-indent: -999px;
}

.nav-tabs .dropdown-menu li {
  display: block;
}

.nav-tabs li.active .dropdown-menu a {
  color: #5b5656;
}

.nav-tabs.refactored-tabs > li > a {
  margin-right: 4px;
}

.tab-content {
  background-color: #fff;
}

.tab-content-loading {
  padding: 20px;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.row-headerless > [class*="span"],
.tab-pane-headerless .row:first-child [class*="span"] {
  padding-top: 20px;
}

.row-headerless > .span3.no-padding-top {
  padding-top: 0;
}

.row-footerless > [class*="span"],
.tab-pane-footerless .row:last-child [class*="span"] {
  padding-bottom: 20px;
}

.tab-pane .intab-cancel-wrapper {
  text-align: right;
  padding: 20px;
}

.tab-pane .intab-cancel {
  color: var(--font-color);
  cursor: pointer;
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
  padding: var(--mid-spacing) var(--lg-spacing);
  transition: var(--transition);
  position: relative;
}

.tab-pane .intab-cancel::before {
  content: " ";
  display: inline-block;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!--! Font Awesome Pro 6.1.1 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons  Inc. --%3E%3Cpath fill='rgb(217, 115, 97)' d='M310.6 361.4c12.5 12.5 12.5 32.75 0 45.25C304.4 412.9 296.2 416 288 416s-16.38-3.125-22.62-9.375L160 301.3L54.63 406.6C48.38 412.9 40.19 416 32 416S15.63 412.9 9.375 406.6c-12.5-12.5-12.5-32.75 0-45.25l105.4-105.4L9.375 150.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L160 210.8l105.4-105.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-105.4 105.4L310.6 361.4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: contain;
  height: 12px;
  width: 12px;
  padding-right: 3px;
  margin-bottom: -2px;
}

.tab-pane .intab-cancel:hover {
  background: var(--light-gray);
  text-decoration: none;
}

.tab-pane-padded {
  padding: 20px;
}

.tab-content-failed {
  padding: 20px 20px 0.1px 20px;
}

.tab-header {
  display: flex;
  align-items: center;
}

.tab-header h2 {
  margin: 0;
}

/* ========== Controls on tabs ============= */

.controls-tabs {
  position: relative;
}

.controls-tabs .inline-controls {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px 20px;
}

/* ========== Secondary tabs ============= */

.widget-performance .nav-tabs,
.tab-content .nav-tabs,
.box-content .nav-tabs {
  margin: 20px;
}

.widget-performance .nav-tabs li,
.tab-content .nav-tabs li,
.box-content .nav-tabs li {
  line-height: 20px;
}

.widget-performance .nav-tabs a:hover,
.tab-content .nav-tabs a:hover,
.box-content .nav-tabs a:hover {
  text-decoration: none;
}

.widget-performance .nav-tabs li a,
.tab-content .nav-tabs li a,
.box-content .nav-tabs li a {
  color: var(--font-light);
  font-size: 14px;
  padding: 5px 0;
  margin-right: 15px;
  background-color: transparent;
}

.widget-performance .nav-tabs .active a,
.tab-content .nav-tabs .active,
.box-content .nav-tabs .active {
  border-bottom: 3px solid var(--primary-color);
  color: var(--font-color);
}

.nav-tabs.refactored-tabs {
  height: 40px;
}

.nav-tabs.nav-secondary {
  display: block;
  width: 100%;
  border-bottom: 1px solid var(--light-gray);
}

.datepicker .btn {
  border-top-left-radius: var(--radius);
  border-bottom-left-radius: var(--radius);
}

.nav-tabs.nav-secondary,
.nav-tabs.nav-secondary .nav-link {
  background-color: #f9fafb;
}

.nav-tabs.nav-secondary .nav-link.active {
  color: var(--dark-gray);
  border-bottom: 4px solid var(--primary-color);
}

.nav-tabs .nav-link {
  background-color: var(--light-gray);
  border: 1px solid var(--light-gray);
}

.nav-tabs .nav-link:hover {
  color: var(--primary-color);
  border: 1px solid var(--light-gray);
}

.tab-content {
  background-color: var(--light-gray-bg);
  display: block;
  border: 1px solid var(--light-gray);
}

.tab-content .tab-content {
  border: none;
}

.nav-tabs a.nav-link.active,
.nav-tabs .nav-item.active .nav-link {
  background-color: var(--light-gray-bg);
  color: var(--primary-color);
  cursor: default;
  pointer-events: none;
  border-bottom: none;
}

/* ========== Responsive ============= */

@media (max-width: 1200px) {
  .nav-tabs.resp-tabs li {
    display: flex;
  }
}

@media (max-width: 767px) {
  .nav-tabs li {
    margin: 1px 0;
    width: 100%;
  }

  .nav-tabs li a {
    display: block;
  }
}
