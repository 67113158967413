.info-cards {
  padding: var(--gutter-w) 0 var(--gutter-w) var(--gutter-w);
}

.info-cards--no-gutter {
  padding-left: 0;
}

.info-cards > div {
  align-items: stretch;
}

.info-card {
  margin: 0 var(--gutter-w) 0 0;
  background: white;
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
  height: 100%;
}

.info-card--no-gutter {
  margin-right: 0;
}

.info-card p {
  font-size: var(--font-size-heading);
  margin: 0;
}

.info-card .top {
  border-bottom: 1px solid var(--light-gray);
  padding: var(--gutter-w);
}

.info-card .top--has-checkbox {
  padding: calc(var(--gutter-w) - 2px) var(--gutter-w);
  display: flex;
}

.info-card .top--has-checkbox h3 {
  line-height: 20px;
}

.info-card .top--hide-body-if-checked:has(label.checkbox > input:checked) {
  border-bottom: none;
}

.info-card .top--hide-body-if-checked:has(label.checkbox > input:checked) ~ .body,
.info-card .hide-sibling-if-checked:has(label.checkbox > input:checked) ~ .hide-sibling {
  display: none;
}

.top--hide-body-if-checked ~ .body {
  padding: 0 !important;
}

.top--hide-body-if-checked ~ .body .d-block {
  padding: 1rem 1rem 0 1rem;
}

.info-card .body ul {
  margin-left: 0;
  list-style-type: none;
}

.info-card .body ul li {
  margin-bottom: var(--gutter-w);
}

.info-card .body ul li span,
.info-card .body ul li:last-of-type {
  margin-bottom: 0;
}

.info-card h3,
.info-card h4 {
  font-size: var(--h3-size);
  font-weight: var(--font-weight-bold);
  margin: 0 0 var(--gutter-w) 0;
  padding: 0;
  border: none;
}

.info-card .top h3,
.info-card .top h4 {
  margin-bottom: 0;
  font-size: var(--h3-size);
}

.info-card a {
  color: var(--primary-color);
}

.info-card h5 {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight-bold);
  margin: 0;
}

.info-card section:not(:last-of-type) {
  margin-bottom: var(--gutter-w);
}

.info-card .body {
  padding: var(--gutter-w);
}

.row.info-cards [class*="col-"]:first-child p {
  margin: 0;
}

.info-cards--multi .info-card {
  height: auto;
  margin-bottom: var(--lg-spacing);
}
