
input,
button,
textarea,
#redactor_modal input[type=text],
#redactor_modal textarea,
select {
  background-color: #eeeff2;
  border: 1px solid #eeeff2;
  border: none;
  padding: 4px 12px;
}

input,
button,
textarea,
select,
.select2-container-multi .select2-choices,
#redactor_modal input[type=text],
#redactor_modal textarea {
  border-radius: 5px;
}

input:focus,
textarea:focus {
  outline: none;
}

input,
button,
textarea,
select,
#redactor_modal input[type=text],
#redactor_modal textarea {
  font-size: 0.92em;
  line-height: 2em;
}

textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder,
::placeholder,
.select2-default,
.redactor_placeholder {
  color: var(--font-light);
  opacity: 1;
  font-style: italic;
}

input,
.select2-container-multi .select2-choices .select2-search-field input {
  font-family: var(--font-family);
  font-weight: var(--font-weight-thin);
}

input[type=file] {
  line-height: 2em;
  padding: 5px 9px 3px 9px;
}

select:not(.select2):not([data-controller="symfony--ux-autocomplete--autocomplete"]) {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-image: url('data:image/svg+xml,<svg width="6" height="5" xmlns="http://www.w3.org/2000/svg"><path fill="%23878888" d="M3 5 0 0h6z" fill-rule="evenodd"/></svg>');
  background-repeat: no-repeat;
  background-position: right 0.7em top 50%, 0 0;
}

.control-group {
  padding-left: 20px;
  margin-bottom: 10px;
  line-height: 34px;
}

.control-label {
  font-weight: bold;
}

label.error,
.label.error,
.form-error {
  display: block;
  color: var(--danger-color);
}

.btn-cancel {
  color: var(--danger-color);
  margin-left: 20px;
}

.btn-autofill {
  margin-left: 20px;
}

.row-submit [class*=span] {
  padding: 20px;
}

textarea[disabled],
textarea[readonly],
input[disabled],
input[readonly],
select[disabled],
select[readonly] {
  border: 1px #ddd solid;
  background-color: #f4f4f4;
  color: rgb(127, 127, 127);
}

.checkboxes .controls {
  margin-left: 20px;
}

.delete a {
  color: var(--danger-color);
}

.input-group-text {
  font-size: 14px;
  border-radius: var(--radius);
  letter-spacing: 0;
  font-weight: var(--font-weight-bold);
}

.group-no-margin .form-group {
  margin-bottom: 0;
}

textarea[data-controller="textarea-autogrow"] {
  resize: none;
  min-height: 35px;
}

/* ============ select2 =============== */
.select2-container .select2-choice,
.select2-container-multi .select2-choices {
  background: #f4f5f7;
  padding: 4px 12px;
}

.select2-container.form-control {
  padding: 0;
}

.select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background-color: transparent !important;
}

.select2-container-active .select2-choice,
.select2-container-multi.select2-container-active .select2-choices {
  box-shadow: none;
}

.select2-drop,
.select2-container .select2-choice,
.select2-container-multi .select2-choices,
.select2-container-active .select2-choice,
.select2-container-multi.select2-container-active .select2-choices,
.select2-container .select2-choice .select2-arrow,
.select2-dropdown-open.select2-drop-above .select2-choice {
  border: none;
}

.select2-container-multi .select2-choices .select2-search-field input {
  padding: 0;
}

.select2-container .select2-choice .select2-arrow {
  background: none;
}

.select2-container .select2-choice .select2-arrow b {
  background-position: -4px 7px;
}

.form-control.valid + .invalid-feedback {
  display: none !important;
}

.select2-drop {
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border-top-left-radius: 0;
}

.select2-search input {
  border-color: var(--light-gray);
  border-radius: var(--radius);
}

.select2-results {
  padding: 0;
  margin: var(--thin-spacing) 0 0 0;
}

.select2-result,
.select2-no-results,
.select2-results .select2-searching {
  border-bottom: 1px #eee solid;
  padding: 8px 10px;
  letter-spacing: 0;
  font-weight: var(--font-weight);
}

.select2-result:last-child {
  border-bottom: none;
}

.select2-results .select2-highlighted {
  color: var(--font-color);
  background-color: var(--blue-lighter);
}

.select2-container-multi .select2-choices .select2-search-choice {
  background: #fff;
  border-radius: 2px;
  border: none;
  margin-left: 0;
  margin-right: 5px;
}

.select2-container {
  position: relative;
}

.select2-clearer {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: calc(0.75rem - 5px + 1.2rem);
  height: 100%;
  transition: opacity 0.5s;
  cursor: pointer;
  color: var(--font-color) !important;
  text-decoration: none !important;
  line-height: 33px;
  opacity: 0;
}

.select2-container:hover .select2-clearer {
  opacity: 1;
}

.select2-dropdown-open .select2-choices {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

/* =========== horizontal column forms ========== */
.columns-form .delete .btn {
  padding: 6px;
  margin-left: 2px;
}

.columns-form .delete a {
  color: #fff;
}

.columns-form input,
.columns-form select,
.columns-form .select2-container,
.columns-form .input-append input[type=text] {
  width: 100%;
  max-width: 100%;
}

.columns-form .columns-form-actions {
  text-align: center;
}

.columns-form .columns-form-actions > div,
.columns-form .columns-form-actions > a {
  display: inline-block;
}

.columns-form .collection-item .control-group {
  padding-left: 0;
}

/* =========== horizontal forms ========== */

.form-horizontal .control-label {
  text-align: left;
  line-height: 24px;
}

.form-horizontal .control-label-fixed {
  float: left;
  font-weight: bold;
}

.form-horizontal textarea,
.form-vertical textarea {
  height: 200px;
}

.form-horizontal input[type=text],
.form-horizontal input[type=number],
.form-horizontal input[type=date],
.form-horizontal textarea {
  min-width: 80%;
  max-width: 80%;
}

.form-horizontal .select2-container {
  max-width: 88%;
  min-width: 88%;
}

.collection-inline > .control-group > .controls,
.form-horizontal .controls .controls {
  margin-left: 0;
}

.form-horizontal .controls .control-group {
  padding-left: 0;
}

.form-horizontal .subcontrols .controls {
  margin-left: 160px;
}

.form-horizontal .radio {
  display: block;
}

.form-horizontal .full-label .control-label {
  width: auto;
}

/* ============= filter forms ================ */

.box-filter .select2-container,
.header-filter .select2-container {
  width: 70% !important;
}

.box-filter [type=text] {
  width: 65%;
}

.header-filter {
  margin-top: 25px;
}

/* ========== inline collections =========== */

.collection-inline > .row:first-child [class*=span] {
  padding-left: 20px;
}

.collection-inline .add {
  margin-left: 20px;
}

.collection-inline-headers {
  font-weight: bold;
}

/* ============== coverage sections ============= */

.sections-wrap .btn.add-section {
  margin-left: 20px;
}

.sections > .collection-item {
  border-top: 1px #eee solid;
  padding: 20px 20px 20px 0;
}

.sections > .collection-item:last-child {
  border-bottom: 1px #eee solid;
  margin-bottom: 20px;
}

.sections .edit-control {
  float: right;
}

.sections .edit-control,
.sections .edit-control a {
  color: #c33;
  cursor: pointer;
  font-size: 0.9em;
}

.sections .edit-control:hover,
.sections .edit-control a:hover {
  text-decoration: underline;
}

.sections > .collection-item .controls {
  margin-left: 0;
}

.sections .controls .section-name {
  max-width: 40%;
  min-width: 40%;
}

.sections .sub-sections .control-group {
  padding: 0;
}

.sections .fields .control-group {
  margin-bottom: 5px;
}

.sections .field .control-group {
  width: 40%;
  display: inline-block;
}

/* =========== datepicker =========== */

.datepicker .clear-wrap {
  position: relative;
  display: inline;
}

.datepicker .clear {
  position: absolute;
  right: 12px;
  top: -8px;
}

.form-binding .policy-num,
.datepicker input[type=text] {
  min-width: 60%;
  max-width: 60%;
}

.datepicker input[readonly] {
  border: none;
}

.ui-datepicker {
  position: absolute !important;
}

.ui-datepicker.ui-corner-all {
  border-top-left-radius: 0;
}

.ui-datepicker-month,
.ui-datepicker-year {
  height: 21px;
  padding: 0;
}

form .delete .btn {
  padding: 4px 6px 4px;
}

.datepicker label.error {
  position: absolute;
  top: -8px;
  left: 7px;
}

.ui-datepicker-today a {
  font-weight: bold;
}

/* =========== input with button ========== */

.form-binding .policy-num,
.datepicker input[type=text] {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.form-binding .row .controls .alert.alert-error.no-programmes,
.form-binding .btn-group-programmes .btn,
.datepicker .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin-left: -2px;
  padding: 9px;
  box-shadow: none;
}

.form-binding .btn-group-programmes .dropdown-menu {
  top: 175%;
}

/* ============ customer select boxes ============ */

.select2-result-customer .inactive {
  text-decoration: line-through;
}

/* ============ agency select boxes ============ */

.select2-container.agency .select2-chosen {
  width: 100%;
}

.select2-result-agency {
  display: flex;
  justify-content: space-between;
}

.select2-result-agency-address {
  opacity: 0.6;
}

/* ============ user select boxes ============ */

.select2-result-user .inactive {
  text-decoration: line-through;
}

.select2-result-user .avatar,
.select2-result-user .name,
.select2-result-agent .avatar,
.select2-result-agent .name,
.select2-result-inspection-company .avatar,
.select2-result-inspection-company .name,
.select2-result-marketing-company .avatar,
.select2-result-marketing-company .name {
  display: inline-block;
  vertical-align: top;
}

.select2-result-user .avatar {
  border-radius: 2px;
}

.select2-result-user .name {
  line-height: 40px;
  margin-left: 8px;
}

/* =========== saving forms ============ */

.progress {
  position: relative;
  overflow: hidden;
  margin: 0;
  border: 1px #eee solid;
  border-radius: 3px;
}

button.progress {
  text-align: left;
  vertical-align: middle;
  height: calc(100% - 30px);
}

.form-footer button.progress {
  height: 100%;
}

.box .top .progress {
  display: inline-block;
  padding: 6px 12px;
  margin-top: -7px;
  margin-right: -13px;
}

.box .body .progress {
  display: inline-block;
  padding: 3px 6px;
  margin-top: -4px;
  margin-left: -7px;
  overflow: visible;
}

.progress-loader-bar {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
  transition: 0.5s width;
  border-radius: 3px;
}

.progress .progress-loader-bar {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ========= additional insureds ======== */

.additional-insureds > .add {
  margin-left: 20px;
}

.additional-insureds > .control-group > .controls {
  margin-left: 0;
  margin-right: 20px;
}

.additional-insureds .insured-type .controls {
  width: 200px;
}

.additional-insureds .insured .controls .controls {
  margin-left: 140px;
}

.additional-insureds .address .controls .controls {
  margin-left: 0;
}

.additional-insureds > .control-group,
.additional-insureds .insured > .control-group {
  margin-bottom: 0;
}

.additional-insureds .delete {
  float: right;
}

.additional-insureds .delete a {
  color: #c33;
}

/* =========== inputs with appended controls ========= */

.input-append {
  white-space: nowrap;
}

.input-append input[type=text],
.input-append .progress {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-append input[type=text] {
  min-width: 70%;
  max-width: 70%;
}

.input-append .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
  padding-top: 9px;
  padding-bottom: 9px;
}

/* ============== fancy toggle button =============== */
.toggle-component {
  display: flex;
  align-items: center;
}

.toggle-component:not(:has(> .tgl[disabled])):hover {
  cursor: pointer;
}

.toggle-component .label-inner {
  font-weight: var(--font-weight);
  line-height: 16px;
  display: inline-block;
  padding-left: 6px;
  padding-top: 1px;
}

.tgl {
  display: none !important;
}

.tgl,
.tgl::after,
.tgl::before,
.tgl *,
.tgl *::after,
.tgl *::before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}

.tgl::-moz-selection,
.tgl::after::-moz-selection,
.tgl::before::-moz-selection,
.tgl *::-moz-selection,
.tgl *::after::-moz-selection,
.tgl *::before::-moz-selection,
.tgl + .tgl-btn::-moz-selection {
  background: none;
}

.tgl::selection,
.tgl::after::selection,
.tgl::before::selection,
.tgl *::selection,
.tgl *::after::selection,
.tgl *::before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: block;
  width: 32px;
  height: 16px;
  position: relative;
  cursor: pointer;
  background: var(--input-bg);
  border-radius: 2em;
  border: 1px solid var(--mid-gray);
  padding: 2px;
  transition: var(--transition);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.toggle-component:hover .tgl:not([disabled]) + .tgl-btn,
.tgl:not([disabled]) + .tgl-btn:hover {
  border-color: var(--primary-color);
}

.tgl[disabled] + .tgl-btn {
  background: var(--light-gray-bg);
  border-color: var(--disabled-dark-color);
  cursor: default;
}

.tgl + .tgl-btn::after {
  border-radius: 50%;
  background: var(--mid-gray);
  transition: var(--transition);
}

.tgl[disabled] + .tgl-btn::after {
  background: var(--disabled-dark-color);
}

.tgl + .tgl-btn::after,
.tgl + .tgl-btn::before {
  position: absolute;
  top: 2px;
  display: block;
  content: "";
  width: 10px;
  height: 10px;
}

.tgl + .tgl-btn::after {
  left: 3px;
}

.tgl + .tgl-btn::before {
  display: none;
}

.tgl:checked + .tgl-btn::after {
  left: 18px;
  background-color: #fff;
}

.tgl:checked[disabled] + .tgl-btn::after {
  background-color: var(--disabled-dark-color);
}

.tgl:checked + .tgl-btn {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.tgl:checked[disabled] + .tgl-btn {
  background: var(--light-gray-bg);
  border-color: var(--disabled-dark-color);
}

/* ============== fancy ticked checkbox =============== */

.form-horizontal input.checkbox-tick,
.checkbox-tick {
  display: none;
}

.checkbox-tick[disabled] + label {
  opacity: 0.5;
  background: #d2d2d2;
}

.checkbox-tick[disabled] ~ span {
  opacity: 0.5;
}

.checkbox-tick + label {
  display: inline-block;
  border: 1.7px #aaa solid;
  color: #aaa;
  border-radius: 3px;
  width: 16px;
  height: 16px;
  line-height: 18px;
  text-indent: -9999px;
  overflow: hidden;
  cursor: pointer;
  margin-bottom: 0;
}

.checkbox-tick.partial + label,
.checkbox-tick:checked + label {
  text-indent: 3px;
  background-color: #3bb2ed;
  border-color: #3bb2ed;
  color: #fff;
  font-size: 12px;
  letter-spacing: 100px;
  line-height: 16px;
}

.checkbox-tick.partial + label {
  background-color: rgba(59, 178, 237, 0.3); /* #3bb2ed */
  border-color: rgba(59, 178, 237, 0.3); /* #3bb2ed */
}

.checkbox-tick.partial + label::before,
.checkbox-tick:checked + label::before {
  content: "✔";
}

.checkbox-tick-numbered:checked + label {
  font-weight: bold;
  text-align: center;
  text-indent: 0;
  letter-spacing: normal;
}

.checkbox-tick-numbered:checked + label::before {
  content: "";
}

.checkbox-tick-container {
  padding-top: 5px;
}

/* ============== fancy ticked checkbox rounded =============== */

.checkbox-tick.rounded + label {
  width: 24px;
  height: 24px;
  text-indent: 6px;
  line-height: 25px;
  font-size: 14px;
  border: 1px #aaaaaa70 solid;
  color: #aaaaaa70;
  border-radius: 20px;
}

.checkbox-tick.rounded + label::before {
  font-family: FontAwesome, sans-serif;
  content: "\f00c";
}

.checkbox-tick.rounded:hover + label {
  color: #3bb2ed59;
  border-color: #3bb2ed59;
}

.checkbox-tick.rounded:checked + label {
  background-color: transparent;
  border-color: #3bb2ed;
  color: #3bb2ed;
}

/* ============= checkbox folder ================ */

.checkbox-folder {
  display: none;
}

.checkbox-folder + label {
  cursor: pointer;
  border: 1px #ddd solid;
  display: block;
  border-radius: 5px;
  background-color: #ddd;
  overflow: hidden;
}

.checkbox-folder + label .document-count,
.checkbox-folder + label .tag-name {
  display: inline-block;
  vertical-align: middle;
  line-height: 25px;
}

.checkbox-folder + label .document-count {
  width: 30px;
  text-align: center;
}

.checkbox-folder + label .tag-name {
  width: calc(100% - 30px);
  background-color: #fff;
}

.checkbox-folder + label .tag-name .inner {
  display: block;
  padding: 0 10px;
}

.checkbox-folder + label.droppable-active {
  border-color: #4ecaab;
  background-color: #4ecaab;
}

.checkbox-folder + label.droppable-active .tag-name .inner {
  background-color: rgba(78, 202, 171, 0.1);
}

.checkbox-folder:checked + label .tag-name {
  font-weight: bold;
  background-color: transparent;
}

/* ============= toggleable ================== */

.toggleable {
  display: none;
}

/* ============= Agent form ================== */

.form-horizontal .select2-container.contact-status {
  min-width: calc(88% - 96px);
}

/* ============= form sublists ================== */

.form-sublist {
  background: linear-gradient(rgba(242, 242, 242, 0.55), #fff);
  border-radius: 6px;
}

.form-sublist .form-sublist-item {
  margin-top: 10px;
}

.form-sublist .heading-padding {
  padding-top: 20px;
}

.form-sublist > .control-group > .controls {
  margin-left: 0;
}

.form-sublist .collection-item:not(:nth-of-type(1)) .form-sublist-header {
  display: none;
}

/* ============= form utils ================== */

.extra-width label.control-label {
  width: 175px;
}

.money {
  text-align: right;
}

.big-decimal {
  text-align: right;
}

/* ============== ToggleButton =============== */

.switch-field {
  overflow: hidden;
  line-height: 25px;
  margin-bottom: 10px;
}

.switch-field input {
  display: none;
}

.switch-field label {
  float: left;
}

.switch-field.switch-three-elements label {
  width: 30%;
}

.switch-field.switch-two-elements label {
  width: 47%;
}

.switch-field label {
  display: inline-block;
  background-color: var(--light-gray-bg);
  padding: 3px 0;
  color: var(--font-light);
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  text-shadow: none;
  border: 1px solid var(--light-gray);
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -ms-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.switch-field label:hover {
  cursor: pointer;
  color: var(--primary-color);
}

.switch-field input:checked + label {
  background-color: var(--primary-color);
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  cursor: default;
}

.switch-field > label:first-of-type,
.switch-field span:first-of-type label {
  border-radius: 4px 0 0 4px;
}

.switch-field > label:last-of-type,
.switch-field span:last-of-type label {
  border-radius: 0 4px 4px 0;
  border-right: 1px solid var(--light-gray);
}

.switch-field > label,
.switch-field span label {
  border-right: none;
}

/* ================= radio tabs ================== */
.radio-tabs input {
  display: none;
}

.radio-tabs label {
  cursor: pointer;
  color: #4b5167;
  font-size: 14px;
  font-weight: 500;
  padding: 5px 0;
  margin-right: 15px;
  background-color: transparent;
  opacity: 0.5;
}

.radio-tabs input:checked + label {
  opacity: 1;
  border-bottom: 3px solid #20b1ef;
}

/* ================= production reports ================== */

.form-production-report .collection-inline > .row:first-child [class*=span] {
  margin-left: 0;
}

.form-production-report .first-section {
  margin-top: 25px;
}

.form-production-report .collection-item .group {
  padding: 20px;
  border-radius: 6px;
  border: 1px solid #dedede;
  margin: 10px 0;
}

.form-production-report .collection-item > .row {
  margin-bottom: 10px;
}

.form-production-report .span-team .select2-container,
.form-production-report .span-user .select2-container {
  width: 80%;
  float: right;
}

.form-production-report .row-comment {
  padding: 10px 0 30px;
  text-align: center;
}

.form-production-report .row-user .span-team,
.form-production-report .row-team .span-user {
  display: none;
}

.form-production-report .control-group {
  padding: 0 20px;
}

.form-production-report .percentage-input {
  width: 37px;
}

.form-production-report .hidden-group {
  margin-top: 10px;
}

.form-production-report .hidden-group label {
  font-size: 0.8em;
}

ol.alpha {
  list-style-type: lower-alpha;
}

ul.circle {
  list-style-type: circle;
}

ul.disc {
  list-style-type: disc !important;
}

#release-hazards ol,
#release-hazards ul {
  padding: 0;
  margin-left: 40px;
  line-height: 1.2em;
}

.custom-product-form-section .custom-product-form-section {
  border-left: 4px solid #eee;
  margin: 20px;
  padding-left: 20px;
}

.custom-product-form-collection-item-delete {
  text-align: right;
}

/* Inline forms inside horizontal forms, for nicer dynamic product forms */
.form-horizontal label.force-inline {
  float: none !important;
}

.form-horizontal label.force-inline + .controls {
  margin-left: inherit !important;
  margin-right: -30px;
}

/* Forms listing on quotes/endorsements */

.table-forms label.checkbox {
  display: inline-block;
}

label {
  line-height: 20px;
  margin-bottom: 4px;
}

label.checkbox {
  display: inline-flex !important;
  position: relative;
  cursor: pointer;
  margin-bottom: 0;
  margin-right: 1rem;
}

label.checkbox > input[type=checkbox] {
  margin-right: 0;
}

label.checkbox.disabled,
label.checkbox:has(input:disabled) {
  cursor: default;
}

label.checkbox input {
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  appearance: none;
}

label.checkbox .checkmark {
  display: inline-block;
  position: relative;
  height: 20px;
  min-width: 20px;
  margin-right: 8px;
  background-color: #fff;
  border-radius: var(--radius);
  border: 1px solid var(--light-gray);
  transition: var(--transition);
}

.checkmark-help {
  margin-left: 32px;
  margin-top: -5px;
}

label.checkbox input.error + .checkmark,
label.checkbox input[type="checkbox"]:focus + .checkmark {
  border-color: var(--danger-color);
}

label.checkbox:hover input:not([disabled]) ~ .checkmark:not(.unchecked) {
  border-color: var(--primary-color);
}

label.checkbox input:checked ~ .checkmark:not(.unchecked) {
  background-color: var(--primary-color);
}

label.checkbox input:disabled ~ .checkmark,
label.checkbox input:disabled:checked ~ .checkmark {
  background-color: var(--light-gray);
  cursor: default;
}

label.checkbox svg {
  align-self: center !important;
}

label.checkbox .checkmark::after {
  content: "";
  position: absolute;
  display: none;
}

label.checkbox input:checked ~ .checkmark::after {
  display: block;
}

label.checkbox .checkmark:not(.unchecked)::after,
label.checkbox .checkmark.checked::after {
  left: 6px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkbox-inline,
.checkbox-inline .form-group {
  display: flex;
  align-items: center;
}

.checkbox .label-inner {
  font-weight: var(--font-weight);
}

label.boxed {
  padding: var(--lg-spacing);
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}

label.boxed.checked {
  border-color: var(--primary-color);
  background-color: var(--light-gray);
}

.input-container {
  position: relative;
}

input.date-picker {
  text-align: right;
}

input.date-picker.active {
  border-color: var(--primary-color);
}

input.date-picker ~ .fa-calendar-alt {
  position: absolute;
  left: 12px;
  top: 8px;
  font-size: 18px;
  color: var(--primary-color);
}

input,
select,
input[readonly],
textarea,
.form-control,
.form-control[readonly],
.select2-container,
#redactor_modal input[type="text"],
#redactor_modal textarea {
  border-radius: var(--radius);
  background-color: var(--input-bg);
  border: 1px solid var(--light-gray);
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight);
  color: var(--font-color);
  scroll-margin-top: 200px;
  font-variant-numeric: tabular-nums;
}

input:not(:disabled):-webkit-autofill,
input:not(:disabled):-webkit-autofill:hover,
input:not(:disabled):-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
}

.form-group .CodeMirror {
  border: 1px solid var(--light-gray);
  border-radius: var(--radius);
}

.form-group .CodeMirror-gutters {
  min-height: 100%;
}

.redactor_editor {
  border-color: var(--light-gray);
  border-radius: 0 0 var(--radius) var(--radius);
}

.redactor_toolbar {
  border: 1px solid var(--light-gray);
  border-bottom: none;
  box-shadow: none;
  border-radius: var(--radius) var(--radius) 0 0;
}

input:hover,
select:not([disabled]):hover,
.form-control:not([disabled]):not(.ts-wrapper):hover,
.select2-container:not(.select2-container-disabled):hover,
label.checkbox input[type=checkbox]:not(.error):focus + .checkmark,
input:focus,
select:focus,
select.is-focused,
.form-control:focus,
.form-group .CodeMirror:hover,
.form-group .CodeMirror:has(textarea:focus) {
  border: 1px solid var(--primary-color);
  transition: var(--transition);
}

.form-group .CodeMirror:has(textarea.error) {
  border: 1px solid var(--danger-dark) !important;
}

input:-webkit-autofill:focus {
  border: 1px solid var(--primary-color);
}

input[readonly].is-invalid,
.form-control[readonly].is-invalid {
  background-color: var(--white-bg);
  border: 1px solid var(--red-color);
}

.form-control[disabled],
.form-control[readonly] {
  background-color: var(--disabled-color);
}

input.date-picker {
  padding: 0.375rem 0.75rem;
}

input.mw-delete {
  max-width: calc(100% - 30px);
}

.new-ui label {
  margin-bottom: 6px;
}

.collection-item .close {
  z-index: 1;
}

.new-ui .collection-item fieldset.form-group legend.col-form-label {
  padding-top: 0;
  padding-bottom: 6px;
  line-height: 20px;
}

.form-control {
  font-size: var(--font-size-heading);
  padding: 0;
  height: auto;
}

input:focus,
select:focus,
input.active {
  border-color: var(--primary-color);
  outline: none;
  box-shadow: none !important;
}

input.error,
textarea.error,
select.error,
.select2-container.error,
input.error:focus,
input[type="date"].error:focus,
textarea.error:focus,
select.error:focus,
.select2-container.error:focus,
*:not(.form-search) > .select2-container.error .select2-choices {
  border-color: var(--danger-dark);
}

.error,
.invalid-feedback {
  color: var(--danger-dark);
}

.invalid-feedback {
  line-height: 1.4;
}

.select2-results .select2-disabled {
  display: none;
}

.select2-container.select2-container-disabled .select2-choice {
  border: none;
}

.table-listing tr.sub-row .select2 a {
  text-decoration: none;
}

/* ======== Select2 overrides ======== */
.select2-results::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.select2-results::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.select2-results::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--light-gray);
}

*:not(.form-search) > .select2-container {
  display: flex;
}

*:not(.form-search) > .select2-container .select2-choice {
  height: 33px;
  color: var(--font-color);
  box-sizing: border-box;
  background-color: var(--input-bg);
  font-weight: normal;
  padding-left: 12px;
  border-radius: var(--radius);
}

.select2-container-multi.select2-container-disabled .select2-choices,
#s2id_applicationFilter_users.select2-container-disabled,
#s2id_policyFilter_user_id.select2-container-disabled {
  border: none;
}

.select2-container-multi.select2-container-disabled .select2-choices {
  padding-left: 0;
}

.select2-container-multi .select2-choices {
  padding-right: 2rem;
}

.select2-container-multi .select2-choices .select2-search-choice {
  line-height: 14px;
  border: none;
  border-radius: 100px;
  font-weight: bold;
  padding: 5px 21px 5px 12px;
  font-size: 10px;
  box-shadow: none;
  background-color: var(--primary-color);
  color: #fff;
}

.select2-container-multi .select2-search-choice .select2-search-choice-close,
.select2-container-multi .select2-search-choice .select2-search-choice-close:hover {
  background-color: var(--primary-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E") !important;
  background-size: 12px 12px !important;
  background-position: center center !important;
  right: 5px;
  left: auto;
  height: 12px;
  width: 12px;
}

.select2-container-multi.select2-container-disabled .select2-choices .select2-search-choice {
  background-color: var(--light-gray);
  color: #333;
  padding: 5px 12px;
  border: none;
}

.select2-default {
  color: var(--font-light) !important;
}

.select2-search-choice-close {
  top: 6px;
}

input.select2-default {
  min-width: 150px !important;
}

.select2-container .select2-choice,
.select2-container-multi .select2-choices {
  padding: 3px 6px;
}

.select2-container-multi::after {
  border-width: 5px 3px 0 3px;
  border-color: #878888 transparent transparent transparent;
  border-style: solid;
  width: 0;
  height: 0;
  position: absolute;
  right: 15px;
  top: 50%;
  content: " ";
  margin-top: -2px;
}

.form-claims-report .select2-container-multi .select2-choices .select2-search-field input.select2-active {
  background-image: none !important;
}

.select2-search input {
  border-color: var(--light-gray);
  border-radius: var(--radius);
  font-size: var(--font-size-heading);
  font-family: var(--font-family);
  color: var(--font-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath  fill='rgb(75, 81, 103)' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E") !important;
  background-position: right 10px center !important;
  background-size: 12px 12px !important;
}

*:not(.form-search) > .select2-container .select2-choices {
  box-sizing: border-box;
  background-color: var(--input-bg);
  background-image: none;
}

input.form-control,
textarea.form-control,
select.form-control {
  padding: 0.375rem 0.75rem;
}

.select2-container.select2-dropdown-open,
.select2-container.select2-dropdown-open .select2-choices,
.select2-container:not(.error).select2-container-active {
  border-color: var(--primary-color);
  transition: none;
}

.form-assignees .modal-body .collection-item .select2-container.select2-dropdown-open {
  border: 1px solid  var(--primary-color);
}

.form-assignees .modal-body .collection-item .select2-container-disabled {
  border: none;
}

.select2-highlighted {
  border-bottom-left-radius: var(--radius);
}

.select2-drop input:focus {
  border-color: var(--light-gray);
}

.select2-dropdown-open.select2-drop-above .select2-choice {
  background-image: none;
}

.select2-drop {
  border: 1px var(--light-gray) solid;
  margin-top: 0;
}

.select2-drop,
.select2-drop.select2-drop-above {
  box-shadow: var(--shadow);
  border: 1px solid var(--light-gray) !important;
  margin-top: -1px;
  border-radius: var(--radius);
}

.select2-drop-auto-width.select2-drop-above .select2-search {
  padding-top: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* ====== Custom Radio Buttons ====== */
.form-check:not(.form-check-inline):has(input[type="radio"]) {
  display: flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
  margin-bottom: var(--thin-spacing);
}

input[type="radio"] + label {
  font-weight: var(--font-weight);
  padding-left: var(--thin-spacing);
  margin-bottom: 0 !important;
}

input[type="radio"] + label.required::before {
  display: none;
}

input[type="radio"]:not(:disabled) + label:hover {
  cursor: pointer;
}

input[type="radio"] {
  cursor: pointer;
  position: static;
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  margin: 0 !important;
  padding: 2px !important;
  border: solid var(--mid-gray);
  border-width: 2px !important;
  border-radius: 50%;
  appearance: none;
  background-color: transparent;
  outline: none;
  transition: border-color var(--transition);
  flex-shrink: 0;
}

input[type="radio"]:checked {
  border: 2px solid;
  background-clip: content-box;
}

input[type="radio"]:not(:disabled):checked {
  border-color: var(--primary-color);
  background-color: var(--primary-color);
}

input[type="radio"]:disabled {
  border-color: var(--disabled-dark-color);
  background-color: var(--light-gray-bg);
  cursor: auto;
}

input[type="radio"]:disabled:checked {
  background-color: var(--disabled-dark-color);
}

input[type="radio"]:not(:disabled):hover,
input[type="radio"]:focus-visible {
  border: 2px solid var(--primary-color);
}

input[type="radio"]:focus-visible {
  box-shadow: var(--focus-outline) !important;
}

label::before + input:required {
  content: "*";
  width: 8px;
}

label:has(~ input.required)::before,
legend.required::before,
label:not(.checkbox).required::before,
label.checkbox.required::after,
span.required::before,
b.required::before {
  content: '*';
  margin-right: 3px;
  color: var(--danger-color);
  font-weight: bold;
  width: 5px;
  display: block;
  margin-left: -8px;
}

label.checkbox.required::after {
  margin-left: 5px;
}

label:not(.d-none):not(.d-md-none):has(~ input.required),
label:not(.d-none):not(.d-md-none).required,
legend:not(.d-none):not(.d-md-none).required {
  display: inline-flex !important;
}

span.required,
b.required {
  display: inline-flex;
}

input[type="date"] {
  width: 100%;
  line-height: 21px;
  padding: 0.375rem 0.75rem;
}

input[type="color"] {
  width: 100px;
  height: 35px;
  cursor: pointer;
}

input.input-small {
  font-size: 1em;
  line-height: 18px;
  padding: 3px 4px;
}

div.form-text {
  line-height: 21px;
  padding: 0.375rem 0;
}

input[type="radio"] + label.required::before {
  content: '';
  margin-right: auto;
}

.form-check:not(.form-check-inline) {
  display: table;
}

.form-check-inline {
  margin-right: 1.25rem;
}

.form-check-inline label.required::before {
  content: '';
  margin-right: 0;
}

.form-check-inline label {
  margin-bottom: 0;
}

.select2 {
  width: 100%;
}

.accounts-filters .select2 {
  max-width: 100%;
}

/* revert bootstrap-override */
form .row:not(.row-submit):not(.row-with-padding):not(.row-headerless):not(.row-file-tag-headers):not(.row-stats):not(.no-gutters) > [class*='col-'],
.row.new-ui:not(.row-submit):not(.row-with-padding):not(.row-headerless):not(.row-file-tag-headers):not(.row-stats):not(.no-gutters) > [class*='col-'] {
  padding-left: 15px;
  padding-right: 15px;
}

.toggle-switch {
  appearance: none;
  width: 32px;
  height: 16px;
  background: var(--input-bg);
  border: 1px solid var(--mid-gray);
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-right: 0 !important;
  flex-shrink: 0;
}

.toggle-switch::after {
  content: '';
  position: absolute;
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  background: var(--mid-gray);
  border-radius: 50%;
  transition: var(--transition);
}

.toggle-switch:hover:not([disabled]) {
  border-color: var(--primary-color);
}

.toggle-switch[disabled] {
  background: var(--light-gray-bg);
  border-color: var(--disabled-dark-color);
  cursor: default;
}

.toggle-switch[disabled]::after {
  background: var(--disabled-dark-color);
}

.toggle-switch:checked:not([disabled]) {
  background: var(--primary-color);
  border-color: var(--primary-color);
}

.toggle-switch:checked::after {
  transform: translateX(16px);
}

.toggle-switch:checked:not([disabled])::after {
  background: #fff;
}

.toggle-label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.toggle-label:has(.toggle-switch[disabled]) {
  cursor: default;
}

.toggle-label .label-inner {
  font-weight: var(--font-weight);
}

.toggle-off,
.toggle-on {
  opacity: 0.5;
  transition: opacity 0.3s;
}

.toggle-label:has(.toggle-switch:checked) .toggle-on,
.toggle-label:has(.toggle-switch:not(:checked)) .toggle-off {
  opacity: 1;
}
