
.btn,
#redactor_modal footer button.redactor_modal_action_btn {
  display: inline-block;
  color: #fff;
  border-radius: 3px;
  padding: 11px 20px 10px;
  border: none;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  vertical-align: middle;
  line-height: 15px;
  white-space: nowrap;
  letter-spacing: 1.6px;
  text-transform: uppercase;
}

.btn svg {
  height: 1em;
}

.btn:focus {
  box-shadow: none;
}

.btn:focus-visible {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn-small {
  padding: 6px 8px;
  font-size: 0.8em;
}

.btn-large {
  padding: 15px 20px 15px 20px;
}

.btn-circle {
  border-radius: 20px;
  box-shadow: none;
}

.btn-right,
.btn-new {
  float: right;
}

.btn-right,
.btn-new,
.btn-group-right {
  margin: 15px 15px 15px 0;
}

.btn-title-tab {
  margin: 0 15px 10px 20px;
}

.btn { background: #435068; }
.btn:hover { background: #384458; }

.btn-primary,
.btn-primary:disabled { background: #3ab1eb; }
.btn-primary:hover { background: #2d99cf; }

.btn-danger,
.btn-danger:disabled { background: #eb5767; }
.btn-danger:hover { background: #d34e5c; }

.btn-grey {
  background: #fdfdfd;
  color: #000;
}

.btn-grey:hover { background: #ececec; }

.btn-primary-soft {
  color: #fff;
  background: #7fccf2;
  padding: 9px 20px;
  text-transform: uppercase;
}

.btn-primary-soft:hover {
  background: #7cc6e9;
}

.btn-soft.btn-no-border {
  border: none;
}

.btn-soft {
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background: transparent;
}

.btn-soft:hover {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
  background-color: transparent;
}

.btn-transparent-primary {
  color: var(--primary-color) !important;
  border: 1px solid var(--primary-color) !important;
  background: transparent;
}

.btn-transparent-primary:hover,
.btn-transparent-primary:active {
  color: var(--primary-dark) !important;
  border: 1px solid var(--primary-dark) !important;
  background: transparent;
}

.btn-text {
  color: var(--primary-color) !important;
  border: none;
  background: transparent !important;
  text-transform: none;
}

.btn-text:hover {
  color: var(--primary-color) !important;
  background: transparent !important;
  opacity: 0.7;
}

.btn-text-danger,
.btn-text-danger:hover {
  color: var(--danger-color) !important;
}

.btn-success,
.btn-success:disabled { background: #92e439; }
.btn-success:hover { background: #58e426; }

.btn-faded {
  opacity: 0.3;
  transition: opacity 0.3s;
}

.btn-group.open > .btn-faded,
.btn-faded:hover {
  opacity: 1;
  transition: opacity 0.3s;
}

.btn-group .dropdown-menu form {
  display: inline-block;
  width: calc(100% - 40px);
  padding: 10px 20px;
  box-sizing: content-box;
}

.btn-group .dropdown-menu form input {
  padding: 0;
}

.btn-group .dropdown-menu .btn {
  color: var(--font-color);
  font-size: var(--font-size);
  display: inline-block;
  margin: 0;
  border: none;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 0.5px;
}

.btn-full-width {
  width: 100%;
}

/* =============== disabled buttons ================= */

.btn:disabled {
  border: none;
  opacity: 0.5;
  cursor: auto;
  color: #fff;
}

/* ================ bordered buttons =============== */

.btn-border {
  background: none;
  box-shadow: none;
}

.btn-border-small {
  font-size: 0.7em;
}

.btn-border-grey {
  color: #999;
  border: 1px #eee solid;
}

.btn-border-grey:hover {
  background: none;
  border-color: #ccc;
}

.btn-border-dark-grey {
  color: #000;
  border: 1px #ddd solid;
}

.btn-border-dark-grey:hover {
  background: none;
}

/* ========== button groups =========== */

.btn-group {
  position: relative;
}

.btn-group .dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  font-weight: 300;
  font-size: var(--font-size);
  min-width: 160px;
  box-shadow: var(--shadow);
  border-radius: var(--radius);
  margin-bottom: 20px;
  padding: 5px !important;
}

.btn-group .dropdown-menu .progress {
  padding: 15px 20px;
  font-size: 0.9em;
}

.dropup .caret {
  border-top: 0;
  border-bottom: 4px solid #fff;
  content: "\2191";
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  border: none;
}

.btn-group li {
  background-color: #fff;
  text-align: left;
}

.btn-group-left-align li {
  text-align: left;
}

.btn-group form,
.btn-group input {
  cursor: pointer;
}

.btn-group li:last-child {
  border-bottom: none;
}

.btn-group li a {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: var(--radius);
  color: var(--font-color);
}

.nav-tabs .btn-group li a {
  background-color: transparent;
}

.btn-group .dropdown-icon {
  color: var(--primary-color);
  margin-right: 6px;
  margin-left: -3px;
}

.dropdown-menu .dropdown-icon {
  width: 18px;
  margin-left: -6px;
}

.btn-group li form:hover,
.btn-group li a:hover {
  background-color: var(--blue-lighter);
}

.btn-group.open .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group.dropup.open .btn {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.btn-group.open .dropdown-menu {
  display: block;
}

.dropdown-toggle:focus {
  outline: 0;
}

.btn-group .divider {
  height: 1px;
  background-color: #ddd;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.caret.up {
  border-top: none;
  border-bottom: 4px solid;
}

.btn-group.pull-right .dropdown-menu {
  left: auto;
  right: 0;
}

.btn-group.pull-top .dropdown-menu {
  top: auto;
  margin-bottom: 0;
  bottom: auto !important;
}

.btn-group input[type=submit] {
  background: none;
}

/* ============ multi action button ========= */
.btn-multi .btn-multi__main {
  padding-right: 10px;
}

.btn-multi .btn-multi__main.btn-primary + .dropdown-toggle {
  border-left: 1px solid rgba(255, 255, 255, 0.5);
}

.btn-multi .dropdown-toggle {
  padding: 11px 10px 10px 10px;
}

.btn-multi .dropdown-menu {
  margin-bottom: 0 !important;
}

.btn-multi.dropup .dropdown-menu {
  bottom: auto !important;
}

.btn-multi.dropup .btn-secondary .caret {
  border-bottom-color: var(--primary-color);
}

.btn-multi .dropdown-menu .btn {
  background-color: transparent;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  text-decoration: none;
  border-radius: var(--radius);
  color: var(--font-color);
  width: 100%;
}

.btn-multi .dropdown-menu .btn:hover,
.btn-multi .dropdown-menu .btn:active {
  background-color: var(--blue-lighter) !important;
  color: var(--font-color) !important;
  opacity: 1;
}

/* ============ button group status ========= */

.btn-group .status {
  display: inline-block;
}

/* =========== attach file ============ */

.btn-attach-wrap,
.redactor_droparea {
  background-color: #f5f4f7;
  width: 90%;
  margin: 5%;
}

.btn-attach,
.btn-attach:hover,
.redactor_droparea .redactor_dropareabox {
  display: block;
  border: 2px #aaa dashed;
  text-align: center;
  padding: 15px;
  background: transparent;
  color: #999;
  font-size: 1.01em;
  border-radius: 5px;
  box-shadow: none;
}

.btn-attach i {
  margin-top: -1px;
}

.btn:not(.btn-soft, .btn-avatar, .btn-primary-soft, .btn-text) {
  border: 1px solid #435068;
}

.input-group-append .btn {
  border: none;
}

.btn.btn-primary {
  background-color: var(--primary-color);
  color: #fff;
  border-color: var(--primary-color);
}

.btn.btn-primary:disabled {
  background-color: var(--font-light);
  color: #fff;
  border-color: var(--font-light);
}

.btn-duplicate-sub-location {
  padding: 9px 11px;
}

.btn.btn-outline-primary:active,
.btn.btn-outline-primary:focus {
  box-shadow: none;
}

.btn.btn-success {
  background-color: var(--success-color);
  color: #fff;
  border-color: var(--success-color);
}

.btn.btn-danger {
  border-color: var(--danger-color);
}

.btn.btn-outline-danger {
  border-color: var(--danger-color);
  color: var(--danger-color);
  background-color: #fff;
}

.btn.btn-outline-danger:hover {
  color: var(--danger-color);
}

.btn.btn-primary:not(:disabled):hover,
.btn.btn-outline-primary:not(:disabled):hover,
.btn.btn-outline-danger:not(:disabled):hover,
.btn.btn-primary.btn-primary-focus,
.btn.btn-primary:focus,
.btn.btn-outline-primary:focus,
.collection-inline .btn.btn-primary:focus:hover {
  opacity: 0.7;
}

.collection-inline .btn.btn-primary:focus {
  opacity: 1;
}

.btn.btn-secondary,
.btn.btn-outline-primary {
  background-color: var(--secondary-color);
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle,
.show > .btn.btn-outline-primary.dropdown-toggle {
  background-color: var(--secondary-color);
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
.show > .btn.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn.btn-secondary:hover,
.btn.btn-outline-primary:hover,
.btn.btn-secondary.btn-secondary-focus,
.btn.btn-secondary:focus,
.btn.btn-outline-primary:focus {
  border-color: var(--primary-dark);
  color: var(--primary-dark);
}

.btn.btn-outline-primary.disabled,
fieldset:disabled .btn.btn-outline-primary,
.btn-secondary.disabled,
fieldset:disabled .btn.secondary,
.btn-secondary.disabled:hover,
fieldset:disabled .btn.secondary:hover,
.btn-secondary.disabled:focus,
fieldset:disabled .btn.secondary:focus {
  border: 1px solid var(--font-light);
  color: var(--font-light);
  box-shadow: none;
}

.btn-icon {
  font-size: 16px;
  line-height: 1rem;
  padding: 0;
  opacity: 1;
  background-color: transparent;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
}

.btn-icon:hover {
  opacity: 0.7;
}

.btn-icon.btn-delete {
  color: var(--danger-color);
}

.close.btn-delete {
  color: var(--danger-color);
  opacity: 1;
  font-size: 1rem;
  z-index: 1 !important;
}

.delete .btn-delete {
  color: var(--danger-color);
  font-size: 16px;
  line-height: 33px;
  opacity: 1;
  background-color: transparent;
  border: none;
}

.delete .btn-delete:hover {
  color: var(--danger-color);
  opacity: 0.7;
}
