
.table {
  color: var(--font-color);
}

.table-margin {
  width: calc(100% - 40px);
  margin: 0 20px 20px 20px;
}

/* =========== report style table ========== */

.table-report thead th.align-right {
  text-align: right;
}

.table-report thead th.align-center {
  text-align: center;
}

.table-report td:first-child,
.table-report th:first-child {
  padding-left: 20px;
}

.table-custom-report tr.collapse td {
  display: none;
}

.table-custom-report tr.collapse.in td {
  display: table-cell;
}

.table-report .currency {
  text-align: right;
}

/* =========== table sorting ============ */

.tbodysorter thead th,
.tbodysorter thead td,
.sort-column {
  cursor: pointer;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tbodysorter thead th,
.sort-column {
  position: relative;
}

.tbodysorter thead th:not(.table-sort-right)::before,
.sort-column:not(.table-sort-right)::before,
.tablesorter-header:not(.sorter-false):not(.table-sort-right)::before,
.tablesorter-header.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before,
.sort-column.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before {
  display: block;
  content: ' ';
  width: 8px;
  height: 10px;
  position: absolute;
  top: calc(50% - 5px);
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41zm255-105L177 64c-9.4-9.4-24.6-9.4-33.9 0L24 183c-15.1 15.1-4.4 41 17 41h238c21.4 0 32.1-25.9 17-41z'/%3E%3C/svg%3E");
  opacity: 0.4;
}

.table-sort-right .tablesorter-header-inner {
  display: inline-block;
  position: relative;
  padding-left: 10px;
  white-space: nowrap;
}

.table-listing th:nth-child(1).sort-column::before,
.table-listing th.pad-sort-column.sort-column::before,
.table th:nth-child(1).sort-column::before,
.table-report th:nth-child(1).sort-column::before,
.tablesorter-header:not(.sorter-false):nth-child(1)::before {
  left: 10px;
}

thead th.sort-ascending:not(.table-sort-right)::before,
thead td.sort-ascending::before,
.sort-column.sort-ascending::before,
.sort-column.sort-asc::before,
.tablesorter-headerAsc:not(.table-sort-right):not(.sorter-false)::before,
.tablesorter-headerAsc.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before,
.sort-asc.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M279 224H41c-21.4 0-32.1-25.9-17-41L143 64c9.4-9.4 24.6-9.4 33.9 0l119 119c15.2 15.1 4.5 41-16.9 41z'/%3E%3C/svg%3E");
  opacity: 1;
}

thead th.sort-descending:not(.table-sort-right)::before,
thead td.sort-descending::before,
.sort-column.sort-descending::before,
.sort-column.sort-desc::before,
.tablesorter-headerDesc:not(.table-sort-right):not(.sorter-false)::before,
.tablesorter-headerDesc.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before,
.sort-desc.table-sort-right:not(.sorter-false) .tablesorter-header-inner::before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath d='M41 288h238c21.4 0 32.1 25.9 17 41L177 448c-9.4 9.4-24.6 9.4-33.9 0L24 329c-15.1-15.1-4.4-41 17-41z'/%3E%3C/svg%3E");
  opacity: 1;
}

.table-report + .footnote {
  text-align: right;
  padding: 20px 22px;
  background-color: #f2f3f5;
}

/* =========== bordereaux table ========== */

.table-bordereaux tr td {
  border-right: 1px #f2f3f5 solid;
}

/* ================== Table listing filters ================== */

.table-listing-container {
  float: left;
  width: 100%;
  position: relative;
  background: #fff;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
  padding-bottom: 0;
}

.table-listing-container-wide {
  overflow-x: scroll;
}

.table-listing-container .current-filters {
  background: #b8c5d1;
}

.table-listing-container .current-filters:not(:empty) {
  padding-bottom: 10px;
}

.table-listing-container .current-filters .filtered {
  display: inline-block;
  background: rgba(211, 223, 235, 0.9);
  color: #597c94;
  border-radius: 3px;
  padding: 10px 15px 10px 15px;
  border: none;
  font-size: 11px;
  text-decoration: none;
  vertical-align: middle;
  line-height: 16px;
  margin: 10px 0 0 10px;
}

.table-listing-container .current-filters .filtered > span {
  cursor: pointer;
}

/* ================== Sidebar filters ================== */

.table-listing-tools {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  z-index: 1015;
}

.table-listing-tools > div {
  float: right;
  width: 0;
  height: 100%;
  background-color: white;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  transition: width 0.4s;
}

/* ====== Side filters ====== */
.table-listing-tools .sidebar-filters .title {
  background-color: var(--primary-color);
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.table-listing-tools .sidebar-filters .title h3 {
  font-size: 22px;
  font-weight: 500;
  margin: 20px 10px 20px 0;
  margin: 0 auto 0 0;
  flex-grow: 1;
  color: var(--white);
}

.table-listing-tools .sidebar-filters .title button {
  border: none;
  margin: 0 0 0 var(--lg-spacing);
}

.table-listing-tools-on .table-listing-tools {
  width: 100%;
  background-color: rgba(27, 33, 42, 0.8);
}

.table-listing-tools-on .table-listing-tools .sidebar-filters {
  width: 500px;
}

.table-listing-tools .sidebar-filters .body {
  height: calc(100% - 130px);
  overflow: auto;
  padding: var(--lg-spacing) calc(var(--lg-spacing) / 2);
}

.table-listing-tools .sidebar-filters .control-group {
  padding: 0 20px;
  margin-bottom: 10px;
}

.table-listing-tools .sidebar-filters .form-group {
  padding: 0 15px;
}

.table-listing-tools .sidebar-filters .col-6 .form-group {
  padding: 0;
}

.table-listing-tools .sidebar-filters .control-group .controls > .select2-container {
  min-width: 100%;
  max-width: 100%;
}

.table-listing-tools .sidebar-filters .control-group .controls > input {
  width: calc(100% - 25px);
}

.table-listing-tools .sidebar-filters .btn-icon-close {
  float: right;
  margin-left: var(--mid-spacing);
  flex-shrink: 0;
  margin-top: 0;
}

.table-listing-tools .sidebar-filters hr {
  margin: 20px;
  border-color: #ddd;
  border-style: dashed;
}

.btn-icon-close {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 100px;
  border: 1px solid #fff;
}

.btn-icon-close.btn-icon-close.btn-icon-close-small {
  width: 18px;
  height: 16px;
  border: none;
  vertical-align: middle;
}

.btn-icon-close:hover {
  border-color: #ddd;
  cursor: pointer;
}

.btn-icon-close:hover::before,
.btn-icon-close:hover::after {
  background: #ddd;
}

.btn-icon-close::before,
.btn-icon-close::after {
  content: '';
  position: absolute;
  height: 1px;
  width: 60%;
  top: 50%;
  left: 20%;
  margin-top: -1px;
  background: #fff;
}

.btn-icon-close.btn-icon-close-small::before,
.btn-icon-close.btn-icon-close-small::after {
  height: 2px;
  margin-top: -1px;
  background: #3ab1eb;
}

.btn-icon-close::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-icon-close::after {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* ================== Table listing ================== */

.table-listing,
.table-report {
  width: 100%;
  background-color: #fff;
  margin-bottom: 0;
  border-collapse: collapse;
}

.table-listing thead th,
.table-listing td,
.table-report th,
.table-report td,
.table th {
  line-height: 14px;
  padding: 10px;
  text-align: left;
  border-top: none;
  border-bottom: 1px var(--smooth-gray) solid;
  background-color: transparent;
  vertical-align: top;
  font-weight: var(--font-weight);
  font-size: var(--font-size);
  position: relative;
}

.table-listing.no-bottom-border tr:last-of-type thead th,
.table-listing.no-bottom-border tr:last-of-type td,
.table-report.no-bottom-border tr:last-of-type th,
.table-report.no-bottom-border tr:last-of-type td,
.table.no-bottom-border tr:last-of-type th {
  border-bottom: none;
}

.table-listing thead th input[type="text"],
.table-listing td input[type="text"],
.table-report th input[type="text"],
.table-report td input[type="text"],
.table-listing thead th .input-group-text,
.table-listing td .input-group-text,
.table-report th .input-group-text,
.table-report td .input-group-text,
.table-listing th .ts-control,
.table-listing td .ts-control,
.table-report th .ts-control,
.table-report td .ts-control {
  font-size: var(--font-size);
  line-height: 1;
}

.table-listing thead th .input-group-text,
.table-listing td .input-group-text,
.table-report th .input-group-text,
.table-report td .input-group-text {
  padding: 0.375rem;
}

.table-listing thead tr,
.table-report thead tr {
  border: none;
}

.table-listing thead th,
.table-report thead th {
  vertical-align: middle;
  font-weight: var(--font-weight-bold);
  border-bottom: none;
}

th.wrappable {
  white-space: normal;
}

th.unwrappable {
  white-space: nowrap;
}

.table-listing thead th .th-subtitle {
  font-size: 12px;
  font-weight: 300;
}

.table-listing thead th.align-right {
  text-align: right;
}

.table-listing thead th.align-center {
  text-align: center;
}

.table-listing thead .table-listing-titles {
  text-transform: none;
}

.table-listing thead .table-listing-titles a {
  color: var(--primary-color);
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.table-listing thead tr .table-listing-titles a:hover {
  text-decoration: none;
}

.table-listing tr.has-actions td:not(.is-actions) {
  padding: 16px 10px;
}

.table-listing td.is-actions {
  white-space: nowrap;
}

.table-listing th.sort-column {
  cursor: pointer;
}

.table-listing .sort-column .fa-sort,
.table-listing .tablesorter-header-inner .fa-sort {
  width: 6px;
  margin: 0 5px 0 -10px;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
}

.table-listing th.sort-column:nth-child(1) .fa-sort,
.table-listing tr.sort-column:not(.sub-row) td:nth-child(1) .fa-sort,
.table-listing:not(.table-carrier-ranking) th.tablesorter-header:nth-child(1) .fa-sort {
  left: 20px;
}

.table-listing .currency {
  text-align: right;
}

.table-listing tr a {
  font-weight: 500;
  text-decoration: none;
}

.table-listing tr a.btn {
  font-weight: 700;
}

.table-listing tr a:hover {
  text-decoration: underline;
}

.table-listing tr.sub-row .select2 a {
  font-weight: 300;
}

.table-listing tr small {
  font-size: smaller;
}

.table-listing th:nth-child(1),
.table-listing th.pad-sort-column,
.table-listing td.pad-sort-column,
.table-listing tr:not(.sub-row) td:nth-child(1),
.table th:nth-child(1),
.table td:nth-child(1) {
  padding-left: 20px;
}

.table-listing tr.expandable-row:hover td {
  cursor: pointer;
}

.table-listing:not(.row-expanded) tr.expandable-row:hover td {
  box-shadow: 0 2px 7px 0 rgba(0, 0, 0, 0.15);
}

.table-listing tr.expandable-row:hover:not(.expanded) td:not(.pre-sub-table)::after {
  content: "";
  width: 10px;
  height: 100%;
  background-color: #fff;
  position: absolute;
  right: -5px;
  top: 0;
  z-index: 1;
}

.table-listing tr.expandable-row:hover:not(.expanded) td.sub-table::after {
  background-color: #f8f8f8;
}

.table-listing td .btn-group .btn-small {
  padding: 3px 8px;
}

.table-listing-header {
  padding: 20px 20px 0 20px;
}

.table-listing td.indicator div.band {
  position: absolute;
  width: 3px;
  height: 80%;
  left: -3px;
  top: 10%;
  border-radius: 2px 0 0 2px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
}

.table-listing .sub-row td.indicator div.band,
.table-listing:not(.no-hover) tr:hover td.indicator > div.band,
.table-listing tr.expanded td.indicator div.band {
  height: calc(100% + 1px);
  top: 0;
}

.table-listing .sub-row td.indicator div.band {
  border-radius: 0 0 0 5px;
}

.table-listing tr.expanded:not(.sub-row) td.indicator div.band {
  border-radius: 5px 0 0 0;
}

.table-listing tr td.indicator > div.expander {
  display: none;
}

.table-listing tr:hover td.indicator > div.expander,
.table-listing tr.expanded td.indicator > div.expander {
  display: block;
  height: 25px;
  width: 25px;
  color: #fff;
  font-weight: bold;
  border-radius: 100px;
  text-align: center;
  line-height: 24px;
  position: absolute;
  top: calc(50% - 12px);
  left: -14px;
}

.table-listing tr.expanded td.indicator > div.expander {
  left: -17px;
  top: calc(50% - 14px);
  border: 2px solid #e9eff3;
}

.table-listing tr:hover td.indicator > div.expander::before {
  content: '+';
}

.table-listing tr.expanded td.indicator > div.expander::before {
  content: '-';
}

.table-listing .entity-link:hover ~ div.expander::before {
  content: '\f35d' !important;
  font-family: "Font Awesome 5 Free", sans-serif;
}

.table-listing .entity-link:hover ~ div.expander {
  line-height: 27px !important;
}

.table-listing tr:last-child {
  border-bottom: 1px #f2f3f5 solid;
}

.table-listing .table-listing-avatar {
  line-height: 0;
  width: 1px;
  padding-right: 0;
}

.table-listing .agent-name {
  display: block;
  font-size: var(--small-font-size);
  color: var(--font-light);
  font-style: italic;
}

.table-listing .expandable-row.expanded .agent-name,
.table-listing .expandable-row.expanded .currency-negative {
  color: var(--white);
}

.table-listing .name-val {
  list-style-type: none;
}

.table-listing .name-val li {
  display: inline-block;
  vertical-align: top;
  width: 47%;
  padding: 8px 0;
  border-top: none;
  position: relative;
}

.table-listing .name-val li:nth-child(1),
.table-listing .name-val li:nth-child(2) {
  border-top: none;
}

.table-listing .name-val > li:nth-child(even) {
  margin-left: 4%;
}

.table-listing .name-val .name-quotes li {
  border: none;
  padding: 3px;
  width: 100%;
}

.table-listing .name-val label,
.table-listing .name-val span {
  display: inline-block;
  vertical-align: top;
}

.table-listing .name-val label {
  font-weight: bold;
  width: 25%;
}

.table-listing .name-val span {
  width: 70%;
}

.table-listing .name-val .avatar-wrap {
  position: absolute;
  top: 50%;
  margin-top: -20px;
}

.table-listing .name-val span.name-val-side {
  margin-left: 50px;
}

.table-listing a.btn:hover,
.table-listing .dropdown-menu a:hover {
  text-decoration: none;
}

.table-listing .button-question {
  font-weight: bold;
  text-transform: none;
  font-size: 13px;
  margin-right: 15px;
}

.table-listing .fa-times {
  font-size: 16px;
  color: #d3d6d9;
}

.table-listing .fa-check {
  font-size: 16px;
  color: #20b1ef;
}

.table-listing tr.no-results td {
  text-align: left !important;
}

/* ====== New UI Styles for listings table ====== */
.sticky-header {
  position: relative;
}

.table-listing:not(.row-expanded) tr.expandable-row:hover td {
  box-shadow: none;
  background: var(--lightblue-gray);
  background-clip: padding-box;
}

.table-listing tr.expandable-row:hover:not(.expanded) td:not(.pre-sub-table)::after {
  display: none;
}

.table-listing td {
  vertical-align: top;
  background-clip: padding-box;
}

table.table thead tr,
table.table-listing tr:last-child {
  border: none;
  background-clip: padding-box;
}

table.table-listing:not(.table-borderless) tbody tr {
  border: none;
}

.table-listing thead {
  background-clip: padding-box;
}

.table-report thead th,
.table-listing thead th {
  background-color: var(--light-gray-bg);
  box-shadow: 0 -1px 0 inset var(--light-gray);
  font-weight: var(--font-weight-bold);
}

.table-listing thead th.no-border {
  box-shadow: none;
}

.sticky-header thead th {
  z-index: 1;
  border: none;
  box-shadow: 0 -1px 0 inset var(--light-gray);
  background-clip: padding-box;
  position: sticky;
  top: 70px;
}

table.table-listing tbody tr,
table.table thead tr {
  border: none;
}

.table-listing:not(.no-hover) tr:hover td.indicator > div.band {
  height: 100%;
}

.table-listing tr td.indicator > div.band {
  height: 80%;
}

.table-listing .entity-link {
  font-weight: bold;
  margin-top: -10px;
  padding: 10px 0 20px 0;
  display: block;
  width: 100%;
}

.table-listing {
  display: relative;
}

/* ================== Table listing sub-table ================== */

.table-listing th.sub-table {
  background-color: #efefef;
  color: #626b76;
  border-color: #dcdfe2;
}

.table-listing td.sub-table {
  background-color: #f8f8f8;
  color: #4b5167;
  border-color: #f0f0f0;
}

.table-listing td.sub-table a {
  color: #20b1ef;
}

/* ================== Table listing fixed-row ================== */

.table-listing tr.table-fixed-bottom-row-visible {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #3f4754;
  width: 100%;
  color: #fff;
  border: none;
  z-index: 1;
}

.table-listing .table-fixed-bottom-row-visible th {
  padding-top: 20px;
  padding-bottom: 20px;
  border: none;
}

.table-listing .table-fixed-bottom-row-visible th:first-child {
  text-align: right;
  font-weight: 300;
}

/* ================== Table listing results-row ================== */

.table-listing .results-row td {
  background-color: #3f4755;
  color: #fff;
  font-weight: 500;
  opacity: 0.8;
}

.table-listing .results-row td.result-label span {
  letter-spacing: 0.9px;
}

.table-listing .results-row td.result-total span {
  opacity: 1;
}

/* ================== Table sub rows ================== */

.expandable-row.expanded td {
  color: #fff;
  background-color: var(--primary-color);
  opacity: 1;
}

.expandable-row.expanded td a:not(.btn) {
  color: #fff;
}

.expandable-row.expanded td .dropdown-menu a {
  color: #5b5656;
}

.sub-row {
  display: none;
}

.sub-row.expanded {
  display: table-row;
}

.sub-row td {
  border: none;
  padding: 0;
  background-color: var(--blue-lighter);
  vertical-align: top;
}

.sub-row .tab-content {
  background-color: var(--blue-lighter);
}

.sub-row.expanded td {
  opacity: 1;
}

.sub-row .fa-pulse {
  padding: 50px;
}

.sub-row-data {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight);
}

.sub-row-data p,
.sub-row-data .row [class*="col-"] p,
.sub-row-data .row [class*="col-"]:first-child p {
  font-size: var(--font-size-heading);
  margin-bottom: 1rem;
}

.sub-row-data h3 {
  font-size: var(--h3-size);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--lg-spacing);
}

.sub-row-data h5 {
  font-size: var(--font-size-heading);
  font-weight: var(--font-weight);
  color: var(--font-light);
  margin: 0;
}

.sub-row-data section:not(:last-of-type) {
  margin-bottom: var(--lg-spacing);
}

.sub-row-data section p:last-of-type {
  margin-bottom: 0;
}

.sub-row-data > .row {
  border-bottom: 1px solid #e6e6e6;
  display: flex;
}

.sub-row-data > .row > div {
  border-right: 1px solid #e6e6e6;
}

.sub-row-data > .row div.box-content {
  padding: 15px 5px;
}

.sub-row-data > .row div.box-content .nav-tabs {
  margin: 0 0 20px 0;
}

.sub-row-data .btn {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
}

.sub-row-data a:not(.btn) {
  color: #20b1ef;
}

.sub-row-data a.select2-choice,
.sub-row-data .nav-tabs li a {
  color: #4b5167;
}

.sub-row-data .fa-envelope {
  opacity: 0.6;
}

.sub-row-data label,
.sub-row-data h4 {
  font-weight: var(--font-weight-bold);
  color: var(--font-color);
  font-size: var(--font-size-heading);
}

.sub-row-data .select2 {
  width: 100%;
  margin-bottom: 10px;
}

.sub-row-data .important-text {
  font-size: 22px;
  font-weight: 600;
  line-height: 30px;
}

.sub-row-data .box-header {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-sizing: content-box;
  padding: 15px 5px;
}

.sub-row-data .box-header-btns {
  padding: 10px 5px;
}

.sub-row-data .box-header-data {
  float: left;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.sub-row-data .box-header-data label {
  margin: 0 var(--thin-spacing) 0 0;
}

.sub-row-data .producer-info {
  font-weight: var(--font-weight-bold);
  margin: 0 auto 0 0;
}

.sub-row-data .producer-info h3 {
  margin: 0;
}

.sub-row-data .box-header-data.box-header-right {
  float: right;
  margin-right: 0;
}

.sub-row-data .box-header-data ~ .btn {
  float: right;
  margin: 0 0 0 15px;
}

.sub-row-data .box-header-data > div {
  float: left;
}

.sub-row-data .box-header-data .producer-avatar {
  margin-right: 15px;
}

.sub-row-data .contact-selector-container {
  margin-top: 5px;
}

.sub-table-sub-row-data .box-content .box-up,
.sub-table-sub-row-data .box-content .box-down {
  padding: 15px 25px;
}

.sub-table-sub-row-data .box-flex {
  background-color: #d6dfe9;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

td.full-height-cell {
  height: 0;
}

td.full-height-cell .sub-table-sub-row-data {
  display: flex;
  height: 100%;
}

/* ================== Table sorting ================== */

.tablesorter thead th:not(.sorter-false) {
  cursor: pointer;
}

.tablesorter .filtered {
  display: none;
}

.tablesorter .tablesorter-errorRow td {
  text-align: center;
  cursor: pointer;
  background-color: #e6bf99;
}

/* ================== Table horizontal scroll ================ */

.table-horizontal-scroll {
  width: auto;
  overflow-x: scroll;
  position: relative;
}

.table-horizontal-scroll-wrapper {
  position: relative;
}

.table-horizontal-scroll-wrapper .pan {
  height: 100%;
  width: 150px;
  position: absolute;
  top: 0;
}

.table-horizontal-scroll-wrapper .pan-right {
  right: 0;
}

.table-horizontal-scroll-wrapper .pan-left:hover {
  background: -webkit-linear-gradient(left, rgba(140, 140, 140, 0.71), transparent);
  background: -o-linear-gradient(right, rgba(140, 140, 140, 0.71), transparent);
  background: -moz-linear-gradient(right, rgba(140, 140, 140, 0.71), transparent);
}

.table-horizontal-scroll-wrapper .pan-right:hover {
  background: -webkit-linear-gradient(left, transparent, rgba(140, 140, 140, 0.71));
  background: -o-linear-gradient(right, transparent, rgba(140, 140, 140, 0.71));
  background: -moz-linear-gradient(right, transparent, rgba(140, 140, 140, 0.71));
}

/* ========== Responsive ============= */

@media (max-width: 767px) {
  .table-listing-container {
    overflow: auto;
  }

  .table-listing,
  .table-custom-report,
  .table-listing-container .current-filters {
    zoom: 50%;
  }

  .table-listing th,
  .table-listing td {
    background-color: white;
  }

  .table-listing-tools-on .table-listing-tools .sidebar-filters {
    width: 90%;
  }

  .table-listing-tools .sidebar-filters .title {
    padding: 0 30px 45px;
  }

  .sub-row.expanded {
    display: none;
  }
}

tr.data-row td {
  border-bottom: none;
  padding: 15px 5px 0 5px;
}

tr.action-row td {
  padding: 0;
}

tr.action-row td a.btn {
  height: fit-content;
}

/* ========== statuses ========== */
.status {
  white-space: nowrap;
}

.status .circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #a8b0bb; /* grey */
  margin-right: 1px;
}

.status .circle.big {
  width: 20px;
  height: 20px;
}

.status .circle--icon {
  color: #fff;
  width: 13px;
  height: 13px;
  font-size: 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.status-default .circle,
.status-sending .circle,
.status-re-opened .circle,
.status-quoted .circle,
.status-not-issued .circle,
.status .circle.circle-not-issued {
  background-color: var(--primary-color); /* blue */
}

.expandable-row.expanded .status-default .circle,
.expandable-row.expanded .status-sending .circle,
.expandable-row.expanded .status-re-opened .circle,
.expandable-row.expanded .status-quoted .circle,
.expandable-row.expanded .status-not-issued .circle,
.expandable-row.expanded .status .circle.circle-not-issued {
  background-color: var(--white-bg); /* white */
}

.status-selected .circle,
.status-accepted .circle,
.status-sent .circle,
.status-active .circle,
.status-online .circle,
.status-bound .circle,
.status-configured .circle,
.status-rating-success .circle,
.status-success .circle,
.status-settled .circle,
.status-applied .circle,
.status-issued .circle,
.status .circle.circle-issued {
  background-color: var(--success-color); /* green */
}

.status-referred .circle,
.status-out-to-market .circle,
.status-idle .circle,
.status-open .circle,
.status-unconfigured .circle,
.status-warning .circle,
.status-new .circle,
.status-submitted .circle,
.status-sent-to-carrier .circle,
.status-idle .circle {
  background-color: #ffab21; /* orange */
}

.status-closed .circle,
.status-declined .circle,
.status-rejected .circle,
.status-error .circle,
.status-void .circle,
.status-inactive .circle,
.status-cancelled .circle,
.status-noc-pending .circle,
.status-non-renewal .circle,
.status-rating-skipped .circle,
.status-rating-failure .circle,
.status .circle.circle-cancelled,
.status .circle.circle-itc-pending,
.status .circle.circle-noc-pending {
  background-color: var(--red-color);
}

.status-cancelled,
.status-non-renewal,
.status-noc-pending {
  color: var(--red-color);
}

.status-default.band,
.status-quoted.band,
.status-sending.band,
.status-not-issued.band,
.status-re-opened.band {
  background-color: var(--primary-color); /* blue */
}

.status-selected.band,
.status-accepted.band,
.status-sent.band,
.status-bound.band,
.status-active.band,
.status-online.band,
.status-receivable.band,
.status-issued.band,
.status-settled.band {
  background-color: var(--success-color); /* green */
}

.status-referred.band,
.status-out-to-market.band,
.status-idle.band,
.status-open.band,
.status-submitted.band,
.status-idle.band,
.status-sent-to-carrier.band,
.status-new.band {
  background-color: #ffab21; /* orange */
}

.status-closed.band,
.status-declined.band,
.status-rejected.band,
.status-error.band,
.status-inactive.band,
.status-payable.band,
.status-cancelled.band,
.status-non-renewal.band,
.status-itc-pending.band,
.status-noc-pending.band {
  background-color: var(--red-color); /* red */
}

.status-indicator {
  background-color: #a8b0bb;
}

.status-default.status-indicator,
.status-sending.status-indicator,
.status-quoted.status-indicator,
.status-not-issued.status-indicator {
  background-color: var(--primary-color); /* blue */
}

.status-selected.status-indicator,
.status-accepted.status-indicator,
.status-sent.status-indicator,
.status-active.status-indicator,
.status-online.status-indicator,
.status-bound.status-indicator,
.status-receivable.status-indicator,
.status-issued.status-indicator,
.status-settled.status-indicator {
  background-color: var(--success-color); /* green */
}

.status-closed.status-indicator,
.status-declined.status-indicator,
.status-rejected.status-indicator,
.status-error.status-indicator,
.status-inactive.status-indicator,
.status-payable.status-indicator,
.status-cancelled.status-indicator,
.status-noc-pending.status-indicator,
.status-itc-pending.status-indicator {
  background-color: var(--red-color); /* red */
}

.status-referred.status-indicator,
.status-out-to-market.status-indicator,
.status-idle.status-indicator,
.status-open.status-indicator,
.status-new.status-indicator {
  background-color: #ffab21; /* orange */
}

.status-solicited.status-indicator,
.status-pending.status-indicator,
.status-idle.status-indicator {
  background-color: #5d42bf; /* purple */
}

.status-pending-agent.status-indicator {
  background-color: #5d42bf; /* purple */
}

.status-pending-market.status-indicator {
  background-color: #3b6d83; /* teal */
}

.status-price-not-competitive.status-indicator {
  background-color: #f45d3c; /* orange */
}

.status-submitted.status-indicator,
.status-idle.status-indicator {
  background-color: #ffab21; /* orange */
}

.status-renewed.status-indicator {
  background-color: #000; /* black */
}

.status-solicited .circle,
.status-pending .circle,
.status-idle .circle {
  background-color: #5d42bf; /* purple */
}

.status-pending-agent .circle {
  background-color: #5d42bf; /* purple */
}

.status-pending-market .circle {
  background-color: #3b6d83; /* teal */
}

.status-price-not-competitive .circle {
  background-color: #f45d3c; /* orange */
}

.status-submitted .circle,
.status-idle .circle {
  background-color: #ffab21; /* orange */
}

.status-renewed .circle {
  background-color: #000; /* black */
}

.band {
  position: absolute;
  width: 3px;
  bottom: 0;
  top: 0;
  background-color: #a8b0bb; /* grey */
}

.status-solicited.band,
.status-pending.band,
.status-idle.band {
  background-color: #d178ed; /* purple */
}

.status-renewed.band {
  background-color: #000; /* black */
}

.row-band {
  width: 3px;
  padding: 0;
}

table:not(.table-borderless):not(.table-listing):not(.table-report):not(.table-custom-report) tbody tr {
  border: 1px solid var(--light-gray);
}

.expandable-row.expanded td {
  background-color: var(--primary-color);
}

.expandable-row.expanded td .btn-primary {
  border: 1px solid #fff;
}
