/* Overrides for tom-select */
.ts-control {
  border: 1px solid var(--disabled-color);
  background-color: var(--disabled-color);
  border-radius: var(--radius);
  font-size: var(--font-size-heading);
}

.tomselected.error ~ .invalid .ts-control,
.tomselected.error ~ .ts-wrapper .ts-control {
  border: 1px solid var(--danger-color);
}

.ts-control:hover {
  border: 1px solid var(--disabled-color);
}

.focus .ts-control {
  box-shadow: none;
  border-color: var(--disabled-color);
}

.ts-control,
.ts-wrapper.single.input-active .ts-control,
.full .ts-control {
  background: var(--disabled-color);
}

.ts-wrapper.multi .ts-control::after {
  content: " ";
  display: block;
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  border-style: solid;
}

.ts-wrapper.single .ts-control::after,
.ts-wrapper.multi .ts-control::after {
  border-width: 5px 3px 0 3px;
  border-color: #878888 transparent transparent transparent;
  right: calc(0.75rem + 1px);
  margin-top: -2px;
}

.ts-wrapper.single.dropdown-active .ts-control::after,
.ts-wrapper.multi.dropdown-active .ts-control::after {
  border-width: 0 3px 5px 3px;
  border-color: transparent transparent #878888 transparent;
}

.ts-wrapper.multi.has-items .ts-control {
  padding-right: 2rem !important;
}

.ts-dropdown .option,
.ts-dropdown .optgroup-header,
.ts-dropdown .no-results,
.ts-dropdown .create {
  font-size: var(--font-size);
  padding: 12px 10px;
  font-weight: var(--font-weight);
  border-bottom: 1px solid var(--light-gray);
}

.ts-dropdown .optgroup-header {
  color: var(--font-color);
  font-weight: 700;
  background-color: var(--disabled-color);
}

.ts-dropdown .option:last-of-type {
  border-bottom: none;
  border-radius: 0 0 var(--radius) var(--radius);
}

.ts-dropdown .create:hover,
.ts-dropdown .option:hover,
.ts-dropdown .active {
  background-color: var(--blue-lighter);
}

.ts-dropdown,
.ts-dropdown.form-control {
  border: 1px solid var(--disabled-color);
  box-shadow: var(--shadow);
  margin: 0;
}

.plugin-dropdown_input .dropdown-input {
  border: 1px solid var(--disabled-color);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3C!-- Font Awesome Pro 5.15.4 by %40fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --%3E%3Cpath  fill='rgb(75, 81, 103)' d='M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z'/%3E%3C/svg%3E") !important;
  background-position: right 8px center;
  background-size: 12px 12px;
  background-repeat: no-repeat;
  padding: 4px 24px 4px 5px;
  box-sizing: border-box;
}

.plugin-dropdown_input.focus .ts-dropdown .dropdown-input {
  border-color: var(--disabled-color);
  box-shadow: none;
}

.plugin-dropdown_input.focus.dropdown-active .ts-control {
  border: 1px solid var(--disabled-color);
  box-shadow: none;
}

.ts-wrapper.form-control:hover {
  border: none;
}

.ts-dropdown-content {
  padding: 0;
}

.dropdown-input-wrap {
  padding: var(--thin-spacing);
}

.form-horizontal .ts-wrapper {
  max-width: 88%;
  min-width: 88%;
}

.ts-wrapper.multi .ts-control > .item {
  background: #fff;
  border-radius: 2px;
  padding: 3px 5px 3px 0 !important;
  line-height: 13px;
  color: #333;
  box-shadow: 0 0 2px #fff inset, 0 1px 0 rgba(0, 0, 0, 0.05);
  position: relative;
  display: flex;
  flex-direction: row-reverse;
}

.ts-wrapper.plugin-remove_button .item .remove {
  font-weight: bold;
  margin: 0;
  border-left: none !important;
  color: #878888;
}

.ts-wrapper.plugin-remove_button .item .remove:hover {
  background: #fff;
}

.ts-dropdown .optgroup::before {
  display: none;
}

.ts-dropdown .optgroup {
  border-bottom: 1px solid var(--light-gray);
}

.ts-dropdown .optgroup:last-child {
  border-bottom: none;
}

.ts-dropdown .optgroup .option {
  padding-left: 24px;
}

.ts-hide-disabled .option[aria-disabled="true"] {
  display: none;
}
