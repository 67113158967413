/* ========= list =========== */

.files-list {
  list-style-type: none;
}

.files-list .file,
.files-list .loading {
  background-color: #fff;
  padding: 10px 15px 10px 0;
  border-top: 1px var(--light-gray) solid;
}

.history .files-list .file {
  padding: 10px 15px;
}

.files-list .loading:last-child,
.files-list .file:last-child {
  border-bottom: 1px var(--light-gray) solid;
}

.files-list--borderless .file:first-child {
  border-top: none;
}

.files-list--borderless .file:last-child {
  border-bottom: none;
}

.files-list .info {
  display: inline-block;
  vertical-align: top;
  padding-top: 9px;
}

.files-list .title {
  color: #000;
  display: inline-block;
  vertical-align: top;
  word-wrap: break-word;
  word-break: break-all;
  margin-bottom: 3px;
}

.files-list .thumbnail {
  width: 25px;
  margin-right: 1%;
}

.files-list .info {
  width: 60%;
}

.files-list .actions {
  float: right;
}

.files-list .empty {
  padding: 20px;
}

/* ========= table-list =========== */

.files-list-table {
  list-style-type: none;
}

.files-list-table .info {
  display: inline-block;
  vertical-align: top;
}

.files-list-table .title {
  color: #000;
  display: inline-block;
  vertical-align: top;
  word-wrap: break-word;
  word-break: break-all;
  margin-bottom: 3px;
}

.files-list-table .thumbnail {
  width: 25px;
  margin-right: 1%;
}

.files-list-table .info {
  width: 60%;
}

.files-list-table .actions {
  float: right;
}

.files-list-table .empty {
  padding: 20px;
}

.files-list-table .no-results {
  padding: 0;
  font-size: 1em;
  color: #4b5167;
}

/* ============= grid ============= */

.files-grid .file,
.files-grid .loading {
  display: inline-block;
  vertical-align: top;
  margin: 20px;
  max-width: 120px;
  word-break: break-all;
}

.files-grid .loading {
  border: 1px #eee solid;
  padding: 62.5px 10px;
  border-radius: 3px;
  font-size: 1em;
}

.files-grid .actions,
.files-grid .title {
  margin: 8px 0;
  padding: 0;
}

.files-grid .thumbnail img {
  box-shadow: 2px 2px 3px 1px rgba(0, 0, 0, 0.2);
}

/* ========== sidebar-list ============== */

.files-sidebar {
  overflow: auto;
}

.files-sidebar:hover .left-actions {
  opacity: 1;
}

.files-sidebar .left-actions {
  float: left;
  width: 30px;
  opacity: 0;
}

.files-sidebar .right-info {
  float: left;
  width: calc(100% - 30px);
  color: gray;
}

.files-sidebar .right-info input {
  width: 100%;
  box-sizing: border-box;
}

.files .files-sidebar .action a,
.files .files-sidebar .action i {
  padding: 0;
}

.files-sidebar .file-icon {
  font-size: 20px;
}

.files-sidebar .info {
  margin-top: 12px;
}

.files-sidebar .info a {
  color: #3ab1eb;
  font-size: 12px;
  text-decoration: none;
}

/* ========== table ============== */

.files-table td {
  font-size: 13px;
  padding: 10px 15px 10px 0;
}

.files-table .thumbnail-wrap {
  display: inline-block;
  position: relative;
}

.files-table .thumbnail {
  max-width: 50px;
  max-height: 30px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}

.files-table .has-preview {
  cursor: pointer;
}

.files-table .thumbnail-preview {
  position: absolute;
  top: -50%;
  left: calc(100% + 20px);
  padding: 5px;
  background-color: #fff;
  border: 1px #f0f0f0 solid;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.files-table .name {
  max-width: 40%;
}

.files-table .title,
.files-table .thumbnail {
  display: inline-block;
  vertical-align: middle;
}

.files-table .title {
  margin-left: 10px;
  max-width: 80%;
  word-break: break-all;
}

.files-table .tags {
  list-style-type: none;
}

.files-table .label {
  padding: 3px 8px;
  font-size: 0.8em;
  background-color: #cacfd5;
  display: inline-block;
  margin-bottom: 3px;
}

.documents-tab .files-table .action-drag,
.documents-tab .file-row {
  cursor: move;
}

.dragging .documents-tab label.folder-label {
  cursor: copy;
}

.dragging .documents-tab .folder-type {
  cursor: no-drop;
}

.documents-tab .files-table .action-drag:active,
.documents-tab .file-row:active,
.ui-draggable-dragging {
  cursor: grabbing;
}

/* ========= file uploading ========= */

.files-list .loading {
  background-image: url('../img/no-thumb-list.png');
  background-repeat: no-repeat;
  background-position: 15px center;
  padding: 20px 20px 20px 49px;
}

.files-list .loaded {
  background-image: url('../img/no-thumb.png');
}

/* ========= adding files ======= */

.file-inputs .file-input {
  border-top: 1px #eee solid;
  padding: 10px 20px;
}

.file-inputs .file-input,
.file-inputs .file-input input {
  background-color: #fff;
}

.file-inputs .file-input:last-child {
  border-bottom: 1px #eee solid;
  margin-bottom: 20px;
}

.btn-add-file-wrap {
  padding: 0 20px;
  text-align: right;
}

/* ========== tags =========== */

.files-list .tag,
.files-grid .tag {
  display: inline;
}

.files-table .tag,
.files-list .tag,
.files-grid .tag {
  text-transform: lowercase;
}

.files-table .tag-type-folder {
  display: none;
}

/* ========== actions ========== */

.files .actions {
  list-style-type: none;
  white-space: nowrap;
  display: flex;
  justify-content: flex-end;
}

.files .action-drag {
  cursor: move;
}

.ui-draggable .action-drag {
  display: inline-block;
}

.files .action a,
.files .action i {
  font-size: 14px;
  width: 14px;
  height: 17px;
  padding: 0 0 0 0.5rem;
}

.files .action-edit a,
.files .action-view a {
  color: var(--primary-color);
}

.files .action-delete a {
  color: var(--danger-color);
}

.files .action-delete:hover a,
.files .action-edit:hover a,
.files .action-view:hover a {
  opacity: 0.75;
}

/* ========= documents filter sidebar ========== */

.documents-tab .filter-tags {
  list-style-type: none;
  width: 92%;
  padding: 4%;
}

.documents-tab .box-filter .body h3 {
  font-size: 1em;
  padding: 0;
  margin: 0 0 0 20px;
  border: none;
  font-weight: bold;
}

.documents-tab .filter-tags li {
  display: inline-block;
  vertical-align: top;
  width: 46%;
  margin-bottom: 6px;
  word-break: break-word;
  margin-right: 3%;
  font-size: 13px;
}

.documents-tab .filter-tags-title {
  font-weight: bold;
  border: 0;
  font-size: 1.4em;
  padding: 0;
  margin: 10px 0 10px 20px;
}

.documents-tab .filter {
  padding-left: 3%;
}

.documents-tab .filter input {
  width: 88%;
}

.documents-tab .filter input::-webkit-input-placeholder {
  color: #000;
}

.documents-tab .download p,
.documents-tab .download form {
  margin: 0 20px 20px 20px;
}

.documents-tab .select-files-wrap {
  border-right: 1px #eee solid;
}

/* ========= documents folder sidebar ========== */

.documents-tab .folder-tags .checkbox-folder + label {
  border: none;
  border-radius: 0;
  background-color: transparent;
}

.documents-tab .folder-tags {
  list-style-type: none;
  width: 92%;
  padding: 0 4% 4% 0;
}

.documents-tab .box-folder .body h3 {
  font-size: 1em;
  padding: 0;
  margin: 0 0 0 20px;
  border: none;
  font-weight: bold;
}

.documents-tab .folder-tags li {
  display: inline-block;
  vertical-align: top;
  width: 98%;
  margin-bottom: 6px;
  word-break: break-word;
  margin-right: 3%;
  font-size: 13px;
}

.documents-tab .folder-tags-title {
  font-weight: bold;
  border: 0;
  font-size: 1.4em;
  padding: 0;
  margin: 10px 0 10px 20px;
}

.documents-tab .folder-tags .tag-name {
  padding: 5px 0 5px 15px;
  width: 100%;
}

.documents-tab .folder-tags .folder-reset .tag-name {
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
}

.documents-tab .folder {
  padding-left: 3%;
}

.documents-tab .folder input {
  width: 88%;
}

.documents-tab .folder input::-webkit-input-placeholder {
  color: #000;
}

.documents-tab .download p,
.documents-tab .download form {
  margin: 0 20px 20px 20px;
}

.documents-tab .folder-label {
  border: none;
  background: none;
  border-radius: 0;
}

.documents-tab .checkbox-folder:checked + .folder-label .tag-name {
  color: rgb(53, 168, 225);
}

.documents-tab .checkbox-folder:checked + .folder-label {
  border-left: 3px solid rgb(53, 168, 225);
}

.documents-tab .table-thumbnail-wrap .thumbnail {
  width: 23px;
  height: 30px;
}

.documents-tab .folder-tags-container {
  margin-left: 20px;
}

.documents-tab .folder-tags ul {
  padding-inline-start: var(--mid-spacing);
}

.documents-tab .documents-tab .folder-tags li {
  margin-bottom: 0;
}

.documents-tab .folder-tags label {
  margin-bottom: 5px;
}

.documents-tab .folder-tags li label.open,
.folder-tags li label.open a,
.folder-tags li label.open a:hover {
  font-size: 14px;
  color: #3ab1eb;
}

.documents-tab ul.folder-tags,
ul.folder-tags ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.documents-tab ul.folder-tags ul {
  margin-left: 10px;
  width: 100%;
}

.documents-tab ul.folder-tags > * li {
  margin: 0;
  padding: 0 7px;
  line-height: 20px;
  font-weight: bold;
  border-left: 1px solid #768390;
}

.documents-tab .folder-tags > li > ul > li {
  flex-wrap: wrap;
}

.documents-tab .folder-tags ul > li {
  flex-wrap: nowrap;
}

.documents-tab .folder-tags > li > ul > li,
.documents-tab .folder-tags ul > li {
  display: flex;
  width: 100%;
}

.documents-tab ul.folder-tags li:last-child {
  border-left: none;
}

.documents-tab ul.folder-tags li:last-child > label {
  margin-left: 1px;
}

.documents-tab ul.folder-tags > * li::before {
  position: relative;
  top: -0.3em;
  height: 1em;
  width: 12px;
  color: white;
  border-bottom: 1px solid #768390;
  content: "";
  left: -7px;
}

.documents-tab ul.folder-tags li:last-child::before {
  border-left: 1px solid #768390;
}

.documents-tab ul.folder-tags li label:not(.folder-type)::before {
  font-family: 'Font Awesome 5 Free', sans-serif;
  font-style: normal;
  content: "\f07b";
  font-weight: normal;
  font-size: calc(14px * 1.5);
}

.documents-tab ul.folder-tags li > .folder-label.open::before,
.documents-tab ul.folder-tags li > .folder-label.root::before {
  font-family: 'Font Awesome 5 Free', sans-serif;
  font-style: normal;
  content: "\f07c";
  font-weight: normal;
  font-size: calc(14px * 1.5);
}

.documents-tab ul.folder-tags li a,
ul.folder-tags li a:hover {
  text-decoration: none;
  color: #4b5167;
}

.documents-tab .folder-tags .folder-label {
  cursor: pointer;
}

.documents-tab .tab-content .nav-tabs li a {
  opacity: 1;
}

.documents-tab .folder-label.droppable-active {
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0 5px;
  transition: 0.6s ease;
}

.documents-tab .files-table td {
  display: table-cell;
  vertical-align: top;
}

.documents-tab .files-table td:nth-child(1) {
  padding-top: 15px;
}

.documents-tab .files-table td:nth-child(2) {
  padding-top: 13px;
}

.documents-tab ul.folder-tags li label.folder-type {
  font-weight: normal;
}

.documents-tab .filter-tags label {
  margin-bottom: 6px;
}

.documents-tab.files-loading .files-table-container {
  opacity: 0.6;
}

.documents-tab .filter {
  padding-left: 0;
  display: inline-block;
  width: 50%;
}

.documents-tab .row-filter {
  padding: var(--mid-spacing);
  background: var(--light-gray-bg);
  border-bottom: 1px solid var(--light-gray);
  position: sticky;
  z-index: 100;
}

.documents-tab .row-filter .btn-right {
  margin: 0;
}

.alert-primary.ui-draggable-dragging {
  background-color: #3ab1eb;
  border-color: #3ab1eb;
}

.vertical-bottom {
  vertical-align: bottom !important;
}

.dragging .table-hover tbody tr:hover {
  background-color: inherit;
}

.documents-tab .expand-folders {
  display: none;
}

.documents-tab-folders-collapsed .collapse-folders {
  display: none;
}

.documents-tab-folders-collapsed .expand-folders {
  display: block;
}

.documents-tab-folders-collapsed .filter {
  margin-left: 24px;
}

.documents-tab .collapse-folders,
.documents-tab .expand-folders {
  position: absolute;
  top: 13px;
  border: 1px var(--light-gray) solid;
  padding: 5px;
  font-size: 1.2em;
}

.documents-tab .collapse-folders {
  right: 0;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border-right: none;
}

.documents-tab .expand-folders {
  left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-left: none;
  z-index: 1000;
}

.documents-tab .collapse-folders:hover,
.documents-tab .expand-folders:hover {
  background-color: rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

/* ============= edit file dialog ============== */

.form-files-edit .modal-body,
.attachment-modal .modal-body {
  padding: 20px 0 0 0;
}

.form-files-edit .tags h3 {
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.5em;
  margin: 20px 0;
  padding: 0;
  border: none;
}

.form-files-edit .checkbox {
  display: inline-block;
  width: 49%;
}

.form-files-edit .checkbox input {
  cursor: pointer;
}

/* ============ selecting files =============== */

.select-files .success-message {
  display: none;
}

.select-files.success .success-message {
  display: block;
}

.select-files.success .alert {
  margin: 0 !important;
}

/* =============== delete file modal ============== */

.modal-file-delete .modal-body {
  padding: 20px;
  line-height: 1.4em;
}

/* ========== legend =========== */

.legend-title {
  margin: 10px 0 20px 20px;
  padding: 0 0 10px 0;
  font-size: 1.2em;
}

.legend-items {
  list-style-type: none;
  margin: 0 0 20px 20px;
}

.legend-box,
.legend-text {
  display: inline-block;
  vertical-align: top;
  height: 20px;
  line-height: 20px;
}

.legend-box {
  width: 20px;
  height: 20px;
  border-radius: 5px;
}

.legend-box-required {
  background-color: #eb5767;
}

.legend-text {
  font-size: 13px;
}

/* ============ file tag admin ============== */

.row-file-tag-headers {
  font-weight: bold;
}

/* ============ Documents tab nav ============== */

.nav-subtabs {
  padding: 17px 25px;
}

.nav-select-view {
  text-align: right;
}

.nav-select-view li i {
  vertical-align: text-bottom;
}

/* ============ files folder ============== */

.files-folder {
  padding: 0 60px;
}

.files-folder li {
  display: inline-block;
  width: 200px;
  margin-top: 40px;
  text-align: center;
}

.files-folder img {
  max-width: 75px;
  margin-bottom: 10px;
}

.files-folder img.img-loading {
  opacity: 0.3;
}

.files-folder a {
  font-size: 12px;
  text-decoration: none;
}

/* ========== Responsive ============= */

@media (max-width: 1200px) {
  .documents-tab .filter-tags li {
    width: 100%;
  }

  .files-list .info {
    width: 50%;
  }

  .files-list img.thumbnail {
    display: none;
  }

  .form-files-edit .folder-tags-section label {
    position: relative;
    left: 0;
  }

  .form-files-edit .control-label {
    float: none;
  }
}
