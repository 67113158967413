.side-navbar {
  position: sticky;
  top: var(--top-bar-height);
}

.side-navbar .nav {
  margin-bottom: 18px;
  margin-left: 0;
  list-style: none;
}

.side-navbar .nav > li > a {
  display: block;
  color: var(--primary-color);
}

.side-navbar .nav-list > li > a.active,
.side-navbar .nav-list > li > a.active:hover {
  border-left: 3px solid var(--primary-color);
  font-weight: 700;
  transition: border 0.08s;
  color: var(--primary-color);
}

.side-navbar .nav > li > a:hover {
  text-decoration: none;
  background-color: var(--blue-lighter);
}

.side-navbar .nav .nav-header {
  display: block;
  padding: 15px;
  font-weight: bold;
  line-height: 18px;
  color: #999;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  text-transform: uppercase;
}

.side-navbar .nav li + .nav-header {
  margin-top: 9px;
}

.side-navbar .nav-list {
  padding-right: 15px;
  padding-left: 15px;
  margin-bottom: 0;
}

.side-navbar .nav-list > li > a,
.nav-list .nav-header {
  margin-right: -15px;
  margin-left: -15px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
}

.side-navbar .nav-list > li > a {
  padding: 15px;
  text-decoration: none;
}

.side-navbar .nav-list > .active > a,
.side-navbar .nav-list > .active > a:hover {
  border-left: 3px solid var(--primary-color);
  font-weight: bold;
  transition: border 0.08s;
}

.side-navbar .nav-list > li.secondary > a {
  font-size: 12px;
}

.side-navbar .nav-list > li.secondary.active > a,
.side-navbar .nav-list > li.secondary.active > a:hover {
  border-left: none;
  font-weight: bold;
}

.side-navbar .nav-list .divider {
  height: 1px;
  margin: 8px 1px;
  overflow: hidden;
  background-color: #e5e5e5;
  border-bottom: 1px solid #fff;
}

.nav-with-subnav .nav-link + .subnav {
  display: none;
}

.nav-with-subnav .nav-link.active + .subnav {
  display: block;
}

.side-scroller {
  max-height: calc(100vh - var(--top-bar-height));
  overflow-y: auto;
  position: sticky;
  bottom: 44px;
}

ul.side-form-items,
ul.side-form-items ul {
  list-style: none;
}

ul.side-form-items li {
  padding-left: var(--mid-spacing);
}

ul.side-form-items a {
  color: var(--font-color);
}

ul.side-form-items a.active {
  color: var(--primary-color);
}

ul.side-form-items ul li:first-child {
  padding-top: var(--thin-spacing);
}

ul.side-form-items ul li {
  padding-bottom: var(--thin-spacing);
}
